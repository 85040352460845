const primary = Object.freeze({
  navy: '#041e42',
  red: '#ce0037',
  lightblue: '#c7dcf0',
  white: '#fff'
})

const secondary = Object.freeze({
  blue1: '#1d428a',
  blue2: '#407ec9',
  blue3: '#6cace4',
  red2: '#ee3c48',
  red3: '#f3b8b5'
})

const userInterface = Object.freeze({
  black: '#151515',
  darkgray: '#2e2f30',
  coolgrey: '#a9b1b4',
  grey60: '#666',
  grey30: '#b3b3b3',
  grey15: '#d9d9d9',
  grey10: '#e5e5e5',
  grey5: '#f5f5f5',
  grey2: '#fafafa',
  lightgrey10: '#f4f4f7'
})

const complementary = Object.freeze({
  purple: '#752f8a',
  orange: '#ff7f2f',
  yellow: '#f9be00',
  green: '#69b32d'
})

const notification = Object.freeze({
  errorred: '#b81237',
  warningorange: '#dc5800',
  infoblue: '#2e5299',
  successgreen: '#00a908'
})

export default Object.freeze({
  primary,
  secondary,
  userInterface,
  complementary,
  notification
})
