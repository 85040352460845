import store from '@/store'

export default function form(to, from, next) {
  const { isAuthenticated, school } = store.getters
  const schoolCode =
    school?.schoolCode || window.localStorage.getItem('schoolCode')
  // set schoolCode in the localStorage to redirect back to
  // login page of placement not required flow
  if (schoolCode) {
    window.localStorage.setItem('schoolCode', schoolCode)
  }

  if (!isAuthenticated) {
    next({ name: 'noplacement', query: { schoolCode } })
    return
  }

  const initReferenceData = store.dispatch('initReferenceData')
  const getPredictiveSchools = store.dispatch('getPredictiveSchools')
  Promise.all([initReferenceData, getPredictiveSchools])
    .then(() => {
      next()
    })
    .catch((err) => {
      store.commit('setError', err)
      next({ name: 'error' })
    })
}
