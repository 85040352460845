<template>
  <div data-testid="login-otp">
    <Banner :image-src="require('@/assets/icon-school-alt.svg')">
      <template #heading>
        <h1 class="banner__heading">
          Please enter your
          <br />
          6-digit verification code
        </h1>
      </template>
    </Banner>
    <v-container class="form-container centeredContent">
      <OesOtpLogin
        ref="otpLogin"
        :email="email"
        :loading.sync="loading"
        :cognito-session="cognitoSession"
        @authenticated="handleAuthenticated"
      >
        <template #text>
          <p>
            We have sent an email
            <span v-if="email"
              >to <strong>{{ email }}</strong></span
            >
            containing a 6-digit verification code.
          </p>
        </template>
      </OesOtpLogin>
      <p class="return-text">
        Didn't receive a code in your email? You can re-enter your
        <v-btn
          text
          data-testid="return-button"
          class="btn-link"
          @click="reEnterEmail"
        >
          email address
        </v-btn>
      </p>
    </v-container>
  </div>
</template>

<script>
import Banner from '@/components/Banner'
import OesOtpLogin from '@/components/OesOtpLogin'

import { mapGetters } from 'vuex'
import { AUTH_SOURCE, API_ERROR_SUBTYPES, ROUTE_NAMES } from '@/constants'

export default {
  name: 'LoginOTP',
  components: {
    Banner,
    OesOtpLogin
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters([
      'isResuming',
      'cognitoSession',
      'email',
      'promptInProgressApplication',
      'isPNRAdvice',
      'errorType',
      'school',
      'resumeNoApplication'
    ])
  },
  methods: {
    reEnterEmail() {
      const isPnrFlow = this.isPNRAdvice
      const schoolCode = this.school?.schoolCode
      if (isPnrFlow) {
        this.$router.push({
          name: 'noplacement',
          params: { userEmail: this.email },
          query: { schoolCode }
        })
      } else {
        this.$router.push({ name: 'login', params: { userEmail: this.email } })
      }
    },
    async handleAuthenticated(tokens) {
      this.loading = true
      const { idToken, refreshToken } = tokens
      await this.$store.dispatch('setTokens', { idToken, refreshToken })
      this.$store.commit('setAuthSource', AUTH_SOURCE.OTP)
      if (this.isPNRAdvice) {
        try {
          await this.$store.dispatch('createAdvice').then(() => {
            this.$router.push({ name: 'pnrform' })
          })
        } catch (e) {
          this.$store.commit('setError', e)
          this.$router.push({ name: 'error' })
        }
      } else {
        try {
          if (await this.$store.dispatch('beginApplication')) {
            if (this.promptInProgressApplication) {
              this.$router.push({ name: 'pendingapplications' })
            } else if (this.resumeNoApplication) {
              this.$router.push({
                name: ROUTE_NAMES.NOAPPLICATION
              })
            } else {
              this.$router.push({ name: 'form' })
            }
          } else {
            this.$router.push({ name: 'error' })
          }
        } catch (error) {
          this.$store.dispatch('setAPIError', {
            error,
            fallbackCode: API_ERROR_SUBTYPES.fetchApplication
          })
          this.$router.push({ name: 'error' })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.return-text {
  margin-top: 2rem;
  font-size: 1rem; //16px if root font-size is 16px which it appears to be.
  font-weight: 500;
  color: $ads-navy;
  word-break: break-word;
}
.form-container {
  padding-top: 40px;
  border-radius: 4px;
}
</style>
