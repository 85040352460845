<template>
  <div>
    <Banner :image-src="require('@/assets/icon-school-alt.svg')">
      <template #heading>
        <h1 class="banner__heading">
          Placement not required<br />
          in a government high school
        </h1>
      </template>
      <template #sub-heading>
        <h1 class="banner__sub-heading mt-1 mb-0">
          For Year 6 students currently attending a NSW Government School
        </h1>
      </template>
    </Banner>
    <v-container fluid class="centeredContent py-16">
      <OutageNotification />
      <p class="pb-8">
        If you intend to leave the public education system, please complete the
        form below to confirm your child and carer details. If you do not want
        to leave, you can
        <a :href="schoolWebsite" rel="external noopener" target="_blank"
          >return to the school website.
          <span class="sr-only">this link will open in a new tab</span></a
        >
      </p>
      <v-form
        ref="pnrform"
        v-model="valid"
        class="v-form ooa-form"
        lazy-validation
      >
        <!-- Primary school -->
        <v-card class="pa-6">
          <h2 class="mb-8">Let's start with the student's primary school</h2>
          <school-name-lookup
            class="mb-6 pnrSchoolLookup"
            data-testid="schools-lookup"
            ref="schoolLookup"
            label="Primary school"
            :disabled="false"
            required
            :value="schoolName"
            :school="school"
            @change="setSelectedSchoolName(...arguments)"
          />
          <alert
            v-if="showSchoolNameAlert"
            class="mb-6 invalidSchoolAlert"
            icon="mdi-alert"
            role="alert"
            type="warning"
            :html-text="schoolNameAlertMessage"
            in-page
            :border="null"
            data-testid="invalid-school-alert"
          />
          <alert
            v-if="isShowPrefilledAlert"
            in-page
            background-colour
            class="prefilledAlert"
            icon="mdi-alert-circle-outline"
            role="alert"
            :html-text="prefilledInfo"
            :border="null"
            type="info"
            data-testid="prefilled-info"
          />
        </v-card>
        <div v-if="showPnrDetails">
          <!-- Student details -->
          <v-card class="pa-6 my-8">
            <h2 class="mb-8">Student details</h2>
            <TextField
              v-model.trim="firstName"
              class="mb-6"
              label="Student's first name"
              placeholder="Enter first name"
              hint="As shown on their birth certificate"
              :rules="[validateBasicTextOnly]"
              maxlength="100"
              data-testid="student-firstName"
              required
            />

            <TextField
              v-model.trim="familyName"
              class="mb-6"
              label="Student's family name"
              hint="As shown on their birth certificate"
              placeholder="Enter family name"
              :rules="[validateBasicTextOnly]"
              maxlength="100"
              required
              data-testid="student-familyName"
            />

            <TextField
              v-model.trim="preferredName"
              class="mb-6"
              label="Student's preferred name"
              hint="(Optional) This is the name usually used to address your child. It can be different from their birth certificate."
              placeholder="Enter preferred name"
              :rules="[validateBasicTextOnly]"
              maxlength="100"
              data-testid="student-preferredName"
            />

            <DatePicker
              v-model="dateOfBirth"
              :label="`${pluralisedFirstName} date of birth`"
              class="mb-4"
              :date-picker-attrs="{
                min: minBirthDate,
                max: nineYearsAgo
              }"
              year-picker
              date-format="DD-MM-YYYY"
              output-date-format="DD-MM-YYYY"
              required
              :rules="validBirthDate"
              data-testid="student-dateOfBirth"
              persistent-placeholder
            />

            <RadioGroup
              v-model="genderCode"
              class="mt-0 pt-0"
              :items="GENDER_CODES"
              :label="pluralisedFirstName + ' gender'"
              data-testid="student-genderCode"
              required
              :error-messages="blurGenderError"
              @radioblur="handleBlur(genderCode, 'gender')"
              @input="blurGenderError = []"
            />
          </v-card>
          <!-- Intended high school -->
          <v-card class="pa-6 my-8">
            <h2 class="mb-8">Intended high school</h2>
            <p>Which high school are you intending on applying to?</p>
            <SelectMenu
              v-model="schoolType"
              :items="allStates"
              label="Australia/Overseas"
              placeholder="Select..."
              data-testid="schoolType"
              hint="(optional)"
              :aria-label="ariaLabel(schoolType, 'allstates')"
              @input="changeSchoolType"
            />

            <TextField
              v-if="schoolType && isSchoolTypeOverseasOrOther"
              v-model.trim="overseasOtherSchool"
              class="mt-6"
              label="Intended high school"
              placeholder="Enter high school name"
              :rules="[validateBasicTextOnly]"
              maxlength="132"
              data-testid="overseasOtherSchool-Value"
              hint="(optional)"
              @blur="handleOvsOtherSchool"
            />

            <SchoolFinder
              v-if="schoolType && !isSchoolTypeOverseasOrOther"
              id="school-finder"
              ref="schoolFinder"
              v-model="intendedHighSchool"
              class="mt-6 school-finder__search"
              label="Intended high school"
              placeholder="Enter high school name"
              hint="(optional)"
              persistent-hint
              :search-input.sync="schoolSearchInput"
              :clearable="true"
              :menu-props="{ maxHeight: 250 }"
              :min-length-for-search="minLengthForSearch"
              :suggestions-callback="getSchoolSuggestions"
              item-text="displayName"
              :required="
                schoolSearchInput != null && schoolSearchInput.length > 0
                  ? true
                  : false
              "
              @update:school="handleSchoolSelected"
              @input="checkIntendedHS"
            />
          </v-card>
          <v-card class="pa-6 my-8">
            <h2 class="mb-8">Other school options</h2>
            <RadioGroup
              v-model="consideringSelectiveSchool"
              :items="YES_NO"
              label="Have you submitted an application for placement in an academically selective school?"
              data-testid="student-selective"
              required
              :error-messages="blurSelectiveError"
              @radioblur="handleBlur(consideringSelectiveSchool, 'selective')"
              @input="blurSelectiveError = []"
            />
          </v-card>
          <!-- Parent/carer details -->
          <v-card class="pa-6">
            <h2 class="mb-8">Parent/carer details</h2>
            <TextField
              v-model.trim="parentCarerGivenName"
              class="mb-6"
              label="Given name"
              placeholder="Enter given name"
              required
              :rules="[validateBasicTextOnly]"
              maxlength="200"
              data-testid="parentCarerGivenName"
            />

            <TextField
              v-model.trim="parentCarerFamilyName"
              class="mb-6"
              label="Family name"
              placeholder="Enter family name"
              required
              :rules="[validateBasicTextOnly]"
              maxlength="200"
              data-testid="parentCarerFamilyName"
            />

            <TextField
              v-model.trim="parentCarerEmail"
              class="mb-6 parent-carer__email"
              label="Contact email:"
              placeholder=""
              :readonly="true"
              maxlength="200"
              data-testid="parentCarerEmail"
            />
            <div v-for="(contact, idx) in contactDetails" :key="idx">
              <SelectMenu
                v-model="contact.contactType"
                class="mb-6"
                :items="contactTypes"
                label="Phone number type"
                placeholder="Enter type"
                :aria-label="ariaLabel(contact.contactType, 'contacttype')"
                required
                data-testid="contact-contactType"
              />

              <AdsFormatContactField
                v-model.trim="contact.contactValue"
                label="Enter number"
                placeholder="Enter number"
                required
                :rules="[validatePhoneNumber]"
                maxlength="132"
                data-testid="contact-contactValue"
                persistent-placeholder
              />
            </div>
          </v-card>
          <v-card
            class="pa-6 mt-6 mb-6 declarationText"
            data-testid="declaration-section"
          >
            <h3 class="pb-3 navy">Declaration</h3>
            <p class="confirm">
              I confirm that my child will not be requiring placement in a NSW
              Government high school next year.
              <br />
              I have read and understood the information about this submission
              including the collection of personal information.
            </p>
            <p class="navy">
              <strong>Please complete the declaration below:</strong>
            </p>
            <v-checkbox
              ref="declaration"
              v-model="declarationAccepted"
              data-testid="declaration-checkbox"
              @change="validate"
            >
              <template #label>
                <div class="checkbox">
                  I, <b>{{ parentCarerDisplayName }}</b> of
                  <b>{{ parentCarerDisplayEmail }}</b>
                  declare that the information provided is, to the best of my
                  knowledge and belief, accurate and complete.
                </div>
              </template>
            </v-checkbox>
          </v-card>
        </div>
      </v-form>

      <ForwardBackNav
        v-if="showPnrDetails"
        :include-back="false"
        forward-text="Submit"
        :forward-attrs="{ disabled: !forwardButtonEnabled }"
        :forward-loading="forwardLoading"
        @click:forward="submitAdvice"
      />
    </v-container>
    <LogoutConfirmationModal
      v-model="showLeaveModal"
      max-width="550px"
      @logout="handleLogout"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Banner from '@/components/Banner'
import TextField from '@/components/form/TextField'
import RadioGroup from '@/components/form/RadioGroup'
import SelectMenu from '@/components/form/SelectMenu'
import ForwardBackNav from '@/components/ForwardBackNav'
import SchoolFinder from '@/components/OesSchoolFinder'
import SchoolNameLookup from '@/components/SchoolNameLookup.vue'
import OutageNotification from '@/views/OutageNotification'
import { Alert, DatePicker, AdsFormatContactField } from '@nswdoe/doe-ui-core'
import {
  GENDER_CODES,
  ROUTE_NAMES,
  YES_NO,
  API_ERROR_SUBTYPES
} from '@/constants'
import {
  isBasicTextOnly,
  isValidDateFormat,
  isValidPhoneNumber
} from '@/utils/validation'
import LogoutConfirmationModal from '@/views/form/LogoutConfirmationModal'
import moment from 'moment'

// Navigation routes that should NOT be stopped by the 'leaving form' modal when navigating to.
const VALID_NAVIGATION_TARGETS = [
  ROUTE_NAMES.LOGOUT,
  ROUTE_NAMES.CONFIRMATION,
  ROUTE_NAMES.ERROR,
  ROUTE_NAMES.TIMEOUT
]

const mapStudentGettersSetters = (variables) =>
  variables.reduce(
    (result, variable) => ({
      ...result,
      [variable]: {
        get() {
          return this.$store.getters.pnrApplication.student?.[variable]
        },
        set(value) {
          this.$store.commit('updatePNRStudent', { [variable]: value })
        }
      }
    }),
    {}
  )

const mapParentCarerGettersSetters = (variables) =>
  variables.reduce(
    (result, variable) => ({
      ...result,
      [variable]: {
        get() {
          return this.$store.getters.pnrApplication.parentCarers?.[0]?.[
            variable
          ]
        },
        set(value) {
          this.$store.commit('updateParentCarer', {
            parent: { [variable]: value },
            index: 0
          })
        }
      }
    }),
    {}
  )

export default {
  name: 'PnrForm',
  components: {
    Alert,
    Banner,
    TextField,
    RadioGroup,
    DatePicker,
    SelectMenu,
    ForwardBackNav,
    SchoolFinder,
    SchoolNameLookup,
    LogoutConfirmationModal,
    OutageNotification,
    AdsFormatContactField
  },
  data() {
    const dateNineYearsAgo = new Date()
    dateNineYearsAgo.setFullYear(dateNineYearsAgo.getFullYear() - 9)
    const nineYearsAgo = dateNineYearsAgo.toISOString().slice(0, 10)
    const dateSeventeenYearsAgo = new Date()
    dateSeventeenYearsAgo.setFullYear(dateSeventeenYearsAgo.getFullYear() - 17)
    const seventeenYearsAgo = dateSeventeenYearsAgo.toISOString().slice(0, 10)

    return {
      GENDER_CODES,
      YES_NO,
      minBirthDate: seventeenYearsAgo,
      nineYearsAgo,
      validBirthDate: [
        this.validateNotEmpty,
        this.validateIsValidDateFormat,
        this.validateMinimumBirthDate,
        this.validateMaximumBirthDate
      ],
      declarationAccepted: false,
      forwardLoading: false,
      schoolSearchInput: null,
      minLengthForSearch: 3,
      selectedSchoolName: null,
      valid: false,
      schoolType: null,
      overseasOtherSchool: null,
      intendedHighSchool: null,
      blurSelectiveError: [],
      blurGenderError: [],
      showInvalidSchoolNameAlert: false,
      schoolNameAlertMessage: '',
      showLeaveModal: false,
      isShowPrefilledAlert: false
    }
  },
  computed: {
    ...mapGetters([
      'pnrApplication',
      'email',
      'contactTypes',
      'predictiveSchools',
      'school',
      'allStates',
      'oesProperties',
      'validY67TPrimarySchool'
    ]),
    ...mapStudentGettersSetters([
      'familyName',
      'firstName',
      'preferredName',
      'genderCode',
      'dateOfBirth'
    ]),
    ...mapParentCarerGettersSetters([
      'parentCarerGivenName',
      'parentCarerFamilyName',
      'parentCarerEmail',
      'contactDetails'
    ]),
    consideringSelectiveSchool: {
      get() {
        return this.$store.getters.pnrApplication.consideringSelectiveSchool
      },
      set(value) {
        this.$store.commit('setIsPnrSelective', value)
      }
    },
    pluralisedFirstName() {
      const name = this.preferredName || this.firstName
      if (name) {
        return name.trim().slice(-1) === 's' ? `${name}'` : `${name}'s`
      }
      return "Student's"
    },
    parentCarerDisplayName() {
      const { parentCarerGivenName, parentCarerFamilyName } =
        this.pnrApplication.parentCarers[0] || {}
      return parentCarerGivenName && parentCarerFamilyName
        ? `${parentCarerGivenName} ${parentCarerFamilyName}`
        : '(empty)'
    },
    parentCarerDisplayEmail() {
      return (
        this.pnrApplication.parentCarers?.[0]?.parentCarerEmail || '(empty)'
      )
    },
    forwardButtonEnabled() {
      return (
        this.valid &&
        this.declarationAccepted &&
        this.pnrApplication.currentPrimarySchool &&
        !this.hasSchoolNameError
      )
    },
    schoolWebsite() {
      return `${this.school?.website}/about-our-school/enrolment/moving-to-high-school.html`
    },
    isSchoolTypeOverseasOrOther() {
      return this.schoolType === 'OVS' || this.schoolType === 'YYY'
    },
    schoolName() {
      return this.school?.schoolName || ''
    },
    schoolCode() {
      return this.school?.schoolCode || ''
    },
    showSchoolNameAlert() {
      return (
        !this.$refs?.schoolLookup?.$data?.errorMessage &&
        this.showInvalidSchoolNameAlert
      )
    },
    showPnrDetails() {
      return (
        this.schoolName &&
        !this.showInvalidSchoolNameAlert &&
        !this.$refs?.schoolLookup?.$data?.errorMessage
      )
    },
    hasSchoolNameError() {
      return (
        this.$refs?.schoolLookup?.$data?.errorMessage ||
        this.showInvalidSchoolNameAlert
      )
    },
    pilotSchools() {
      return this.$store.getters.pilotSchools
    },
    prefilledInfo() {
      return `<span style='color: #22272B; font-weight: 400'>We have pre-filled your primary school. Please check  this is correct.</span>`
    }
  },
  async beforeRouteLeave(to, from, next) {
    if (VALID_NAVIGATION_TARGETS.includes(to.name)) {
      next()
    } else {
      this.showLeaveModal = true
      next(false)
    }
  },
  async created() {
    if (!this.$store.getters.predictiveSchools) {
      await this.$store.dispatch('getPredictiveSchools')
    }
    if (this.$store.getters.pilotSchools) {
      await this.$store.dispatch('getPilotSchools')
    }
  },
  mounted() {
    this.$store.commit('setParentCarers', [
      {
        parentCarerCompletingApp: true,
        parentCarerEmail: this.email,
        contactDetails: [{}]
      }
    ])
    if (
      this.school?.schoolCode &&
      window.localStorage.getItem('schoolCode') === this.school?.schoolCode
    ) {
      this.isShowPrefilledAlert = true
    }
    window.localStorage.setItem('pnrFlow', true)
  },
  beforeMount() {
    this.$store.commit('setIsResuming', true) // Ensure going 'back' after entering form is considered resuming
    this.$store.commit('setIsPnrAdvice', true)
    this.$activityMonitor.activate()
  },
  beforeDestroy() {
    this.$activityMonitor.deactivate()
  },
  methods: {
    dateStringToDateObject(date) {
      return moment(this.formatDateString(date), 'YYYY-MM-DD')
    },
    formatDateString(date) {
      return moment(date, ['DD-MM-YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD')
    },
    validateBasicTextOnly(text) {
      return (
        isBasicTextOnly(text) ||
        "Can only contain letters, spaces, hyphens, left parenthesis, right parenthesis and apostrophes - ( ) '"
      )
    },
    validateNotEmpty(input) {
      return (
        (input != null && input !== '') || "Student's date of birth is required"
      )
    },
    validateIsValidDateFormat(date) {
      const formattedDate = this.formatDateString(date)
      return isValidDateFormat('YYYY-MM-DD', formattedDate) || 'Invalid date'
    },
    validateMinimumBirthDate(date) {
      const dateObject = this.dateStringToDateObject(date)
      const minBirthDate = moment(this.minBirthDate)
      return (
        dateObject.diff(minBirthDate) >= 0 ||
        'Children enrolling in high school must be no younger than 9 and no older than 17 years of age. Please choose a date of birth within this range.'
      )
    },
    validateMaximumBirthDate(date) {
      const dateObject = this.dateStringToDateObject(date)
      const nineYearsAgo = moment(this.nineYearsAgo)
      return (
        dateObject.diff(nineYearsAgo) <= 0 ||
        'Children enrolling in high school must be at least 9 years of age.'
      )
    },
    validatePhoneNumber(phone) {
      if (phone?.length > 0) {
        return (
          isValidPhoneNumber(phone) ||
          'Can only contain digits, spaces, and - ( ) \\'
        )
      }
      return false || 'Phone number is required'
    },
    checkIntendedHS() {
      if (
        !(
          this.intendedHighSchool && typeof this.intendedHighSchool === 'object'
        )
      ) {
        const schoolDetails =
          this.$store.getters.pnrApplication.intendedHighSchool
        schoolDetails.schoolCode = ''
        schoolDetails.schoolName = ''
        this.$store.commit('updatePNRHighSchool', schoolDetails)
      }
    },
    handleSchoolSelected(school) {
      let schoolDetails
      if (
        school != null &&
        (this.schoolType !== 'OVS' || this.schoolType !== 'YYY')
      ) {
        schoolDetails = {
          schoolCode: school.orgUnitCode,
          schoolName: school.orgUnitName,
          schoolType: this.schoolType
        }
      } else if (school == null) {
        schoolDetails = {
          schoolCode: '',
          schoolName: '',
          schoolType: this.schoolType
        }
      }
      this.$store.commit('updatePNRHighSchool', schoolDetails)
    },
    handleOvsOtherSchool() {
      const schoolDetails = {
        schoolCode: '',
        schoolName: this.overseasOtherSchool,
        schoolType: this.schoolType
      }
      this.$store.commit('updatePNRHighSchool', schoolDetails)
    },
    getSchoolSuggestions(searchInput) {
      if (searchInput == null || searchInput === '') {
        return []
      }
      let suggestedHighSchools

      const labels = {
        centralCommunitySchools: 'Central/Community Schools',
        secSchools: 'Secondary Schools'
      }

      if (this.schoolType === 'NSW') {
        suggestedHighSchools = this.predictiveSchools?.filter(
          (school) =>
            school.stateCode === 'NSW' &&
            school.nswGovSchool === 'N' &&
            (school.catchmentLevel === labels.centralCommunitySchools ||
              school.catchmentLevel === labels.secSchools) &&
            school.orgUnitName.toLowerCase().includes(searchInput.toLowerCase())
        )
      }
      // other states for Overseas and Other it will be free text box
      else {
        suggestedHighSchools = this.predictiveSchools?.filter(
          (school) =>
            school.stateCode === this.schoolType &&
            (school.catchmentLevel === labels.centralCommunitySchools ||
              school.catchmentLevel === labels.secSchools) &&
            school.orgUnitName.toLowerCase().includes(searchInput.toLowerCase())
        )
      }

      suggestedHighSchools.forEach((school) => {
        const schoolData = school
        if (school.suburb !== 'null' && school.suburb !== '') {
          const suburb = school.suburb.trim()
          schoolData.displayName = `${school.orgUnitName} (${suburb})`
        } else {
          schoolData.displayName = school.orgUnitName
        }
      })
      return suggestedHighSchools
    },
    async submitAdvice() {
      try {
        this.forwardLoading = true
        if (this.$refs.pnrform.validate()) {
          await this.$store
            .dispatch('submitAdvice')
            .then((response) => {
              const { eoiID } = response
              this.$store.commit('setApplicationId', eoiID)
              this.$store.dispatch('resetApplication')
              this.$store.dispatch('inactivateSession')
              this.$router.push({ name: 'confirmation' })
            })
            .catch((error) => {
              this.$store.dispatch('setAPIError', {
                error,
                fallbackCode: API_ERROR_SUBTYPES.submitApplication
              })

              this.$router.push({ name: 'error' })
            })
        }
      } catch {
        this.$router.push({ name: 'error' })
      } finally {
        this.forwardLoading = false
      }
    },
    validate() {
      if (!this.$refs.pnrform.validate()) {
        window.scrollTo(0, 0)
      }
    },
    changeSchoolType() {
      //  clear out all inteded high school value
      this.schoolSearchInput = null
      this.overseasOtherSchool = null
      this.intendedHighSchool = null
      // resetting school selection when schooltype is changed
      this.handleSchoolSelected(null)
    },
    handleBlur(input, type) {
      const selectiveSchoolErrorMsg =
        'Please choose if you have submitted an application for placement in an academically selective school'
      const genderErrorMsg = "Student's gender is required"
      if (type === 'selective') {
        this.blurSelectiveError = input !== null ? [] : selectiveSchoolErrorMsg
      }
      if (type === 'gender') {
        this.blurGenderError = input !== undefined ? [] : genderErrorMsg
      }
    },
    ariaLabel(value, objectKey) {
      if (value) {
        const objects = {
          allstates: this.allStates,
          contacttype: this.contactTypes
        }
        const { text } = objects[objectKey].find((item) => item.value === value)
        return text
      }
      return ''
    },
    async handleLogout() {
      this.showLeaveModal = false
      this.$store.dispatch('logout')
      this.$router.push({ name: ROUTE_NAMES.LOGOUT })
    },
    setSelectedSchoolName(name) {
      if (!name || this.hasSchoolNameError) {
        this.isShowPrefilledAlert = false
      }
      if (name) {
        this.selectedSchoolName = name
        const selectedSchoolCode = this.selectedSchoolName.orgUnitCode
        if (selectedSchoolCode) {
          if (this.isValidPrimarySchool(this.selectedSchoolName)) {
            const validPrimarySchool =
              this.validY67TPrimarySchool(selectedSchoolCode)
            this.showInvalidSchoolNameAlert = false
            this.$store.commit('setSchool', validPrimarySchool)
            this.$store.dispatch('setCurrentPrimarySchool', validPrimarySchool)
            if (
              window.localStorage.getItem('schoolCode') !== selectedSchoolCode
            ) {
              this.isShowPrefilledAlert = false
            }
            window.sessionStorage.setItem('schoolCode', selectedSchoolCode)
            window.localStorage.setItem('schoolCode', selectedSchoolCode)
          } else {
            this.schoolNameAlertMessage =
              '<p>You are not eligible to complete this form.</p>'
            this.showInvalidSchoolNameAlert = true
            window.sessionStorage.removeItem('schoolCode')
            window.localStorage.removeItem('schoolCode')
          }
        }
      } else {
        this.showInvalidSchoolNameAlert = false
        this.$store.commit('setSchool', null)
        this.$store.dispatch('setCurrentPrimarySchool', null)
        window.sessionStorage.removeItem('schoolCode')
        window.localStorage.removeItem('schoolCode')
      }
    },
    isValidPrimarySchool(selectedSchoolName) {
      const isNswSchool = selectedSchoolName?.stateCode === 'NSW'
      const isNswGovSchool = selectedSchoolName?.nswGovSchool === 'Y'
      const validPrimarySchool = this.validY67TPrimarySchool(
        selectedSchoolName?.orgUnitCode
      )

      return isNswSchool && isNswGovSchool && validPrimarySchool
    }
  }
}
</script>
<style lang="scss" scoped>
h2 {
  color: $ads-navy;
  font-size: 1.375rem;
  line-height: 1.2;
}
.declarationText {
  font-size: 16px;
  color: $ads-dark-70;
  font-weight: 500;
  .navy {
    color: $ads-navy;
  }
  .confirm {
    font-size: 14px;
    margin-bottom: 30px;
    color: $ads-grey-02;
    margin-top: 0px;
  }
  .checkbox {
    color: $ads-grey-01;
    line-height: 23px;
  }
  h3 {
    font-size: 20px;
  }
  ::v-deep .v-input--selection-controls__input {
    margin-top: 1px;
    margin-right: 15px;
  }
  ::v-deep .v-input__slot {
    align-items: flex-start;
  }
}

.declaration-label {
  line-height: 1.5;
  margin-top: 15px;
}
.parent-carer__email {
  margin-top: 2.5rem;
  ::v-deep label {
    top: 0;
    margin-left: -10px;
  }
}

::v-deep .AppTypeAhead {
  width: 100%;
}

::v-deep .AppTypeAhead_input {
  max-width: 420px;
  width: 100%;
  margin-right: 0;
  background: url('~@/assets/icon-magnify.svg') no-repeat scroll 13px 18px;
  padding: 0 2.5em;
}

.prefilledAlert {
  & ::v-deep .v-alert {
    max-width: 420px;
    box-shadow: none !important;
  }

  & ::v-deep .v-alert__icon {
    margin: auto 16px auto 0;
  }
}
.pnrSchoolLookup {
  max-width: 420px;
}
.invalidSchoolAlert {
  ::v-deep .v-alert {
    background-color: $color-orange-light !important;
    box-shadow: none !important;
    max-width: 420px;

    p {
      margin-bottom: 0 !important;
      font-weight: 400 !important;
      line-height: 19px;
    }
  }

  ::v-deep .theme--light.v-icon {
    color: $ads-warning-orange !important;
  }
}
</style>
