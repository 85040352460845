export default {
  consideringSelectiveSchool: null,
  consideringOoAGovtSchools: null,
  student: null,
  parentCarers: null,
  residentialAddress: null, // pre-app
  currentPrimarySchool: null, // pre-app
  localHighSchool: null,
  nonGovtHighSchool: null,
  ooaHighSchools: [],
  meta: {
    stream: 'y67t', // hardcoded - will not change
    currentStep: 1, // current (active) step
    validStudent: null,
    validParentCarers: null,
    validConsiderations: null,
    validHighSchool: null,
    localSecondarySchools: null // pre-app
  }
}
