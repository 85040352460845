import Vue from 'vue'
import store from '@/store'
import { AUTH_SOURCE, ROUTE_NAMES } from '@/constants'

export default async function home(to, from, next) {
  const { schoolCode } = to.query
  const { address } = to.query
  // Conduct some very basic input checks up front so we can reject if necessary
  // as soon as possible.

  // reset the school data in session storage before running the guard
  window.sessionStorage.removeItem('schoolCode')

  // set idToken and refreshToken from Service NSW login
  // this has been added to guard to prevent return Service NSW user from
  // seeing landing page after authentication
  const idToken = window.localStorage.getItem('id_token')
  const refreshToken = window.localStorage.getItem('refresh_token')
  if (idToken && refreshToken) {
    // Update the store with tokens and app data saved in sessionStorage
    const hydratePromise = store.dispatch('reHydrateDataFromOauth')
    const tokenPromise = store.dispatch('setTokens', { idToken, refreshToken })
    sessionStorage.setItem('authSource', AUTH_SOURCE.SNSW)
    // Remove the tokens from storage
    window.localStorage.removeItem('id_token')
    window.localStorage.removeItem('refresh_token')

    await Promise.all([hydratePromise, tokenPromise])
    // Google Analytics
    Vue.prototype.$gtm.trackEvent({
      event: 'interaction',
      category: 'Authentication',
      action: 'Success',
      label: 'ServiceNSW'
    })
    // check the flow and redirect to PNR advice or eoi
    if (store.getters.isPNRAdvice) {
      try {
        await store.dispatch('createAdvice').then(() => {
          next({ name: 'pnrform' })
        })
      } catch (e) {
        next({ name: 'error' })
      }
    } else {
      try {
        if (await store.dispatch('beginApplication')) {
          if (store.getters.promptInProgressApplication) {
            next({ name: 'pendingapplications' })
          } else if (store.getters.resumeNoApplication) {
            next({
              name: ROUTE_NAMES.NOAPPLICATION
            })
          } else {
            next({ name: 'form', params: { viaServiceNSW: true } })
          }
        } else {
          next({ name: 'error' })
        }
      } catch (e) {
        next({ name: 'error' })
      }
    }
    return
  }

  // `schoolCode` must be comprised of digits with a length between one and four
  if (schoolCode && !/^\d{1,4}$/.test(schoolCode)) {
    Vue.$log.error(`Incorrect schoolCode: ${schoolCode}`)
    store.commit('setError', new Error(`Incorrect schoolCode (${schoolCode})`))
    next('/error')
    return
  }

  if (!store.getters.pilotSchools) {
    await store.dispatch('getPilotSchools')
  }

  if (schoolCode) {
    const validPrimarySchool = store.getters.validY67TPrimarySchool(schoolCode)
    if (!validPrimarySchool) {
      Vue.$log.error(`${schoolCode} is not a valid Y67T school`)
      store.commit(
        'setError',
        new Error(`${schoolCode} is not a valid Y67T school`)
      )
      next('/error')
      return
    }
    window.sessionStorage.setItem('schoolCode', schoolCode)
    store.commit('setSchool', validPrimarySchool)
  }

  // `address` is essentially a free text field and could contain any number of
  // or type of characters, and could potentially be URL encoded. The actual
  // physical address is validated against a Geo DB in subsequent parts of the
  // app, so here we're only concerned with its presence (or lack of).
  if (address) {
    window.sessionStorage.setItem('address', window.decodeURIComponent(address))
  } else {
    window.sessionStorage.removeItem('address')
  }

  next()

  // Explicitly remove the query strings from the browser's URL bar
  // TODO: Further discussion with Robert & Namrata
  // window.history.replaceState(null, '', '/')
}
