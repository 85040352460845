<template>
  <div>
    <Banner
      :image-src="require('@/assets/icon-school-alt.svg')"
      heading="Moving to high school"
    >
      <template #sub-heading>
        <h1 class="banner__sub-heading mt-1 mb-0">
          For Year 6 students currently attending a NSW Government School
        </h1>
      </template>
    </Banner>

    <v-container fluid class="centeredContent py-12">
      <!-- Info cards -->
      <div class="info-card-group">
        <div class="info-wrapper">
          <InfoCard
            id="what-for-info-card"
            :image-src="require('@/assets/icon-school.svg')"
            heading="What is this application for?"
          >
            This form lets you indicate which school you would like your child
            to attend in Year 7. After your expression of interest is processed,
            your child will be offered a place at a government high school.
          </InfoCard>
        </div>
        <div class="info-wrapper">
          <InfoCard
            id="what-need-info-card"
            :image-src="require('@/assets/icon-doc-search.svg')"
            heading="What will I need to complete this form?"
          >
            This form must be completed in English. You will need your child’s
            residential address, their basic details and the contact details for
            one parent/carer.
          </InfoCard>
        </div>
        <div class="info-wrapper">
          <InfoCard
            id="need-help-info-card"
            :image-src="require('@/assets/icon-world-chat.svg')"
            heading="Need help filling out this form?"
          >
            If you require an interpreter to assist you please call
            <strong>131 450</strong> and ask for an interpreter in your
            language. This service will be free of charge to you.
          </InfoCard>
        </div>
      </div>

      <alert
        v-if="isBlockEoiProceed"
        id="blockDateAlert"
        data-testid="block-date-alert"
        in-page
        icon="mdi-alert"
        role="alert"
        type="warning"
        :html-content="blockDateMessage"
        class="blockDateAlert"
      />
      <!-- School finder -->
      <v-card v-else class="addressFinder">
        <h3 class="addressFinder__heading mb-2">
          Let's start with the student's primary school & home address
        </h3>
        <p class="addressFinder__description mb-6">
          We use this to determine the local high school. You may be required to
          provide
          <a
            data-testid="residential-button"
            tabindex="0"
            ref="addFinder"
            @click="handleShowModal"
            @keyup.enter="handleShowModal"
            >proof of student's residential address.</a
          >
        </p>
        <v-row :class="{ 'pb-6': !isSchoolNamePrefilled() }">
          <v-col cols="12">
            <school-name-lookup
              data-testid="schools-lookup"
              ref="schoolLookup"
              label="Primary school"
              :disabled="false"
              :required="true"
              :school="school"
              :value="schoolName"
              @change="setSelectedSchoolName(...arguments)"
            />
            <alert
              v-if="invalidSchoolSelected"
              id="invalidSchoolAlert"
              data-testid="invalid-school-alert"
              in-page
              icon="mdi-alert"
              role="alert"
              type="warning"
              :html-text="invalidSchoolMessage"
              :border="null"
              class="pt-4 pb-0 mb-0 invalidSchoolAlert"
            />
          </v-col>
        </v-row>
        <v-expand-transition>
          <v-row v-show="isSchoolNamePrefilled()">
            <v-col class="oes-address-finder__container--full" cols="12">
              <AddressFinder
                ref="addressFinder"
                label="Student's home address"
                placeholder="Enter student's address"
                :loading="loading"
                :menu-props="{ maxHeight: 250 }"
                :error-messages="errors"
                :min-length-for-search="minLengthForSearch"
                @loadingSuggestions="handleLoadingSuggestions"
                @loadingSelection="handleLoadingSelection"
                @update:search-input="handleSearchInput"
                @update:options="handleOptions"
                @update:address="handleAddressSelected"
                data-testid="address-finder"
              >
                <template #message="{ message }">
                  <div class="addressFinder__error-message">
                    <v-icon class="error-message__icon">
                      mdi-alert-circle-outline
                    </v-icon>
                    <span class="error-message__text" v-html="message" />
                  </div>
                </template>
              </AddressFinder>
            </v-col>
          </v-row>
        </v-expand-transition>
        <v-expand-transition>
          <v-row
            v-show="isSchoolNamePrefilled()"
            class="pt-0 mt-0 pb-4"
            :class="{ 'pb-5': !isSchoolNamePrefilled() }"
          >
            <v-col class="oes-address-finder__container mt-0 pt-0" cols="12">
              <alert
                id="studentAddressInfo"
                in-page
                icon="mdi-alert-circle-outline"
                role="alert"
                :html-text="studentAddressInfo"
                :border="null"
                type="info"
                data-testid="student-address-info"
                class="studentAddressInfoAlert"
              />
            </v-col>
          </v-row>
        </v-expand-transition>
        <v-row v-show="isSchoolNamePrefilled()">
          <TopSlideTransition
            class="schools"
            element-max-height="25rem"
            data-testid="school-list"
            group
          >
            <div v-if="hasSchools" key="school-info" class="school-info">
              <h3 class="school-info__title">
                Your child’s local high school{{ isMultipleSchools ? 's' : '' }}
              </h3>
            </div>
            <SchoolCard
              v-for="(schoolInfo, index) in localSecondarySchoolsForDisplay"
              :key="index"
              :image-src="require('@/assets/icon-school.svg')"
              :radio-attrs="{ value: localSecondarySchools[index] }"
              :school-name="schoolInfo.schoolName"
              :address-text="schoolInfo.addressText"
              :address-url="schoolInfo.addressUrl"
              :phone-number="schoolInfo.phoneNumber"
              :website-url="schoolInfo.websiteUrl"
              :website-text="schoolInfo.websiteText"
            />
          </TopSlideTransition>
        </v-row>
      </v-card>
      <ForwardBackNav
        ref="forwardBackNav"
        :include-forward="canProceed"
        :include-back="false"
        :forward-attrs="{ 'data-testid': 'proceed-button' }"
        @click:forward="handleProceed"
      />
    </v-container>
    <ResidentialAddressModal v-model="showModal" />
  </div>
</template>

<script>
import Banner from '@/components/Banner'
import AddressFinder from '@/components/OesAddressFinder'
import ResidentialAddressModal from '@/views/Home/ResidentialAddressModal'
import InfoCard from '@/components/InfoCard'
import { Alert } from '@nswdoe/doe-ui-core'
import TopSlideTransition from '@/components/TopSlideTransition'
import ForwardBackNav from '@/components/ForwardBackNav'
import SchoolCard from '@/components/SchoolCard'
import utils from '@/mixins/utils'
import { mapGetters } from 'vuex'
import SchoolNameLookup from '@/components/SchoolNameLookup.vue'

export default {
  name: 'EoiForm',
  components: {
    SchoolCard,
    TopSlideTransition,
    ForwardBackNav,
    Banner,
    AddressFinder,
    ResidentialAddressModal,
    InfoCard,
    Alert,
    SchoolNameLookup
  },
  mixins: [utils],
  data() {
    return {
      showModal: false,
      loading: false,
      selectedAddress: null,
      addressSearchInput: null,
      addressSearchOptions: null,
      localSecondarySchools: null,
      minLengthForSearch: 4,
      errors: [],
      selectedSchoolName: null,
      invalidSchoolSelected: false,
      schoolLookupRef: null
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.schoolLookupRef = this.$refs['schoolLookup']
    })
  },
  computed: {
    ...mapGetters([
      'oesProperties',
      'school',
      'isBlockEoiProceed',
      'isBlockEoiProceedClosed'
    ]),
    school() {
      return this.$store.getters.school
    },
    canProceed() {
      return !!this.selectedAddress
    },
    unableToFindAddresses() {
      return (
        this.addressSearchInput?.length >= this.minLengthForSearch &&
        !(this.addressSearchOptions?.length > 0)
      )
    },
    schoolFinderURLs() {
      const endPoints = this.$store.getters.oesProperties.endpoints
      return endPoints?.schoolFinder?.urls
    },
    noAddressMessage() {
      return `We are unable to find your address. <a href="${this.school?.website}" rel="external noopener"
              target="_blank">${this.school?.schoolName} <span class="sr-only">this link will open in a new tab</span></a> will be able to help. Please contact them.`
    },
    localSecondarySchoolsForDisplay() {
      return this.localSecondarySchools?.map(this.transformSchoolForCardProps)
    },
    isMultipleSchools() {
      return this.localSecondarySchools?.length > 1
    },
    hasSchools() {
      return this.localSecondarySchools?.length
    },
    apiErrorMessage() {
      return 'Sorry, we can’t retrieve your address at the moment. Please try again later.'
    },
    y67tEhubMessages() {
      return this.oesProperties?.y67tEhubMessages
    },
    studentAddressInfo() {
      return this.y67tEhubMessages?.studentHomeAddressInfoText
    },
    schoolName() {
      return this.school?.schoolName || ''
    },
    schoolCode() {
      return this.school?.schoolCode || ''
    },
    y67tPIValidationMessages() {
      return this.oesProperties?.y67tPIValidationMessages
    },
    invalidSchoolMessage() {
      const message = this.y67tPIValidationMessages?.invalidSchool || ''
      return `<p>${message}</p>`
    },
    blockDateMessage() {
      return `<span>${
        this.isBlockEoiProceedClosed
          ? 'Expressions of interest process for this year is now closed.'
          : 'Expressions of interest process for this year is not yet open.'
      }</span>`
    }
  },
  async created() {
    this.$store.dispatch('getHighSchools')
    if (!this.$store.getters.predictiveSchools) {
      await this.$store.dispatch('getPredictiveSchools')
    }
    if (this.$store.getters.pilotSchools) {
      await this.$store.dispatch('getPilotSchools')
    }
  },
  methods: {
    addError(error) {
      this.errors = [...this.errors, error]
    },
    clearErrors() {
      this.errors = []
    },
    handleShowModal() {
      this.showModal = true
    },
    handleSearchInput(input) {
      if (this.addressSearchInput !== input) {
        this.clearErrors()
        this.addressSearchInput = input
      }
      this.selectedAddress = null
      this.localSecondarySchools = null
    },
    handleOptions(options) {
      this.addressSearchOptions = options
      if (this.unableToFindAddresses) {
        this.showUnableToFindAddressError()
      }
    },
    showUnableToFindAddressError(delay) {
      if (!delay) {
        this.addError(this.noAddressMessage)
      } else {
        this.loading = true
        setTimeout(() => {
          this.addError(this.noAddressMessage)
          this.loading = false
        }, delay)
      }
    },
    async handleAddressSelected(address) {
      if (address) {
        this.loading = true
        try {
          this.localSecondarySchools = await this.$store.dispatch(
            'getSchoolsForAddress',
            {
              url: this.schoolFinderURLs[0],
              longitude: address.longitude,
              latitude: address.latitude
            }
          )
        } finally {
          this.loading = false
        }
      }
      this.selectedAddress = address
    },
    handleLoadingSuggestions(isLoading, isBadRequest) {
      this.loading = isLoading
      if (isBadRequest) {
        this.addError(this.apiErrorMessage)
      }
    },
    handleLoadingSelection(isLoading) {
      if (isLoading) this.loading = isLoading
    },
    handleProceed() {
      if (this.canProceed) {
        this.$store.commit('resetPreApplication')
        this.$store.dispatch('setResidentialAddress', {
          address: this.selectedAddress
        })

        // Found schools then proceed through normally
        if (this.localSecondarySchools?.length > 0) {
          this.$store.dispatch('setLocalSecondarySchools', {
            schools: this.localSecondarySchools
          })
          this.$router.push({ name: 'privacy' })
        } else {
          // display no school page
          this.$router.push({ name: 'noSchool' })
        }
      }
    },
    transformSchoolForCardProps(school) {
      const long = school?.longitude
      const lat = school?.latitude
      const addressString = this.addressComponentsToString(
        school?.street,
        school?.['town_suburb'],
        'NSW',
        school?.postcode,
        ', '
      )
      return {
        websiteText: school?.website,
        websiteUrl: school?.website && `https://${school.website}`,
        phoneNumber: school?.phone,
        addressText: school && addressString,
        addressUrl:
          long && lat && `https://www.google.com/maps?q=${lat},${long}`,
        // eslint-disable-next-line camelcase
        schoolName: school?.school_name
      }
    },
    setSelectedSchoolName(school) {
      this.invalidSchoolSelected = false
      if (school) {
        this.selectedSchoolName = school
        const selectedSchoolCode = this.selectedSchoolName?.orgUnitCode
        if (selectedSchoolCode) {
          const isNswSchool = this.selectedSchoolName?.stateCode === 'NSW'
          const isNswGovSchool = this.selectedSchoolName?.nswGovSchool === 'Y'
          const validPrimarySchool =
            this.$store.getters.validY67TPrimarySchool(selectedSchoolCode)
          if (isNswSchool && isNswGovSchool && validPrimarySchool) {
            this.$store.commit('setSchool', validPrimarySchool)
          } else {
            this.invalidSchoolSelected = true
          }
        }
      }
    },
    isSchoolNamePrefilled() {
      const typedSchoolName = this.schoolLookupRef?.$data?.typeAheadValue
      if (this.schoolName && this.schoolName === typedSchoolName) {
        return true
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
a:focus {
  border: 2px solid $ads-navy !important;
  outline-color: $ads-navy !important;
}

a {
  text-decoration: underline;
}
.btn-search {
  width: 100%;
}
.errorRed {
  color: $ads-error-red;
}

.info-card-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  margin: 0 -1rem 2rem -1rem;
}

.info-wrapper {
  flex-basis: 33.3333%;
  padding: 1rem;

  @include tablet {
    flex-basis: 50%;
  }

  @include mobile {
    flex-basis: 100%;
  }

  ::v-deep .info-card,
  ::v-deep .info-card__wrapper {
    height: 100%;
  }
}

#what-need-info-card ::v-deep .info-card__icon {
  left: 54%;
  top: 52%;
}

.addressFinder {
  padding: 1.5rem 1.5rem 0 1.5rem;

  &__heading {
    font-size: 1.5rem;
    color: $color-text-body;
  }

  &__description {
    color: $ads-dark;
  }

  &__error-message {
    display: flex;
    align-items: flex-start;
    margin: 0 -8px;

    .error-message__icon,
    .error-message__text,
    .error-message__text ::v-deep a {
      color: $ads-error-red;
    }

    .error-message__text {
      line-height: 1.5;
    }

    .error-message__icon {
      margin-right: 0.5rem;
      font-size: 2rem;
    }
  }
}

.white-card.school-card {
  background: transparent;
  box-shadow: none;
  border-radius: 0;
  padding: 1.5rem 2.75rem;
  margin: 0 -1rem;

  &:not(:last-child) {
    border-bottom: 1px solid $ads-light-20;
  }
}

.school-info {
  padding: 0 1.5rem;

  &__title {
    font-size: 1.5rem;
    color: $color-text-body;
  }
}

.schools {
  width: 100%;
}

.studentAddressInfoAlert {
  ::v-deep .v-alert {
    background-color: $color-ads-primary-teal-2 !important;
    border: none !important;
    box-shadow: none !important;

    &:focus {
      border: none;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.14),
        0 0 0 0 rgba(0, 0, 0, 0.12) !important;
    }
    p {
      margin-bottom: 0 !important;
    }
  }
}
.blockDateAlert {
  ::v-deep .v-alert {
    background-color: $color-orange-light !important;

    .v-alert__wrapper {
      padding: 5px;
    }

    .alert--textsimple {
      color: $ads-grey-01;
    }
  }
}
.invalidSchoolAlert {
  ::v-deep .v-alert {
    background-color: $color-orange-light !important;
    border: none !important;
    box-shadow: none !important;
    border-radius: 4px;

    &:focus {
      border: none;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.14),
        0 0 0 0 rgba(0, 0, 0, 0.12) !important;
    }
    p {
      margin-bottom: 0 !important;
    }
    .v-icon {
      color: $ads-warning-orange;
    }
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 0;
  }
}

::v-deep .AppTypeAhead {
  width: 100%;
}

::v-deep .AppTypeAhead_input {
  width: 100%;
  margin-right: 0;
  background: url('~@/assets/icon-magnify.svg') no-repeat scroll 13px 18px;
  padding: 0 2.5em;
}

::v-deep .hasAlert .AppTypeAhead_input {
  background: url('~@/assets/icon-magnify-red.svg') no-repeat scroll 13px 18px;
}

//Search button + address input animation for mobile
@media (max-width: #{map-get($grid-breakpoints, 'lg') - 1}) {
  .search-button-enter-active,
  .search-button-leave-active {
    transition: all 0.2s;
    overflow: hidden;
  }
  .search-button-enter,
  .search-button-leave-to {
    &,
    & * {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      margin-bottom: 0 !important;
      margin-top: 0 !important;
      max-height: 0 !important;
    }
  }

  .search-button-enter-active,
  .search-button-enter-to {
    &,
    & * {
      max-height: 10rem;
    }
  }
}

//Search button + address input animation for desktop
@media (min-width: #{map-get($grid-breakpoints, 'lg')}) {
  .oes-address-finder__container {
    position: relative;
    transition: all 0.2s;
    transition-delay: 0s;

    &--full {
      transition-delay: 0.2s;
    }
  }

  .search-button-enter-active,
  .search-button-leave-active {
    transition: all 0.2s;
  }
  .search-button-enter,
  .search-button-leave-to {
    opacity: 0;
  }

  .search-button-enter-active {
    position: absolute;
    right: 0.5rem;
    transition: opacity 0.2s;
    transition-delay: 0.2s;
    padding-left: 14px;
    padding-right: 16px;
  }
  .residential-proof-btn {
    margin-left: -2px;
  }
}
</style>
