<template>
  <div class="oes-address-finder">
    <div :id="menuAnchorID" class="menu-anchor" />
    <div id="suggestionsText" class="sr-only">
      Suggestions will appear below as you type more than three characters into
      the field.
    </div>
    <!-- Screen reader message to announce no of options available after the address search. -->
    <div class="sr-only" aria-live="assertive" role="alert">
      {{ noOfSuggestionsMsg }}
    </div>
    <v-combobox
      ref="combobox"
      :items="optionsList"
      :item-text="itemText"
      :search-input.sync="searchInput"
      aria-describedby="suggestionsText"
      v-bind="attrs"
      v-on="$listeners"
      @input="handleSuggestionSelection"
      persistent-placeholder
    >
      <!-- Passes all slots/scopedSlots passed in to this component down into v-combobox -->
      <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
      <template v-for="(_, name) in $slots" #[name]>
        <slot :name="name" />
      </template>
    </v-combobox>
  </div>
</template>

<script>
import _uniqBy from 'lodash/uniqBy'
import oesAddressFinderAPI from 'api-client/oesAddressFinder'

const EVENTS = {
  LOADING_SUGGESTIONS: 'loadingSuggestions',
  LOADING_SELECTION: 'loadingSelection',
  UPDATE_ADDRESS: 'update:address',
  UPDATE_OPTIONS: 'update:options'
}

const AUSTRALIAN_STATES = ['NSW', 'VIC', 'QLD', 'SA', 'WA', 'TAS', 'NT', 'ACT']

export default {
  name: 'OesAddressFinder',
  inheritAttrs: false,
  props: {
    preFilledAddress: {
      type: String
    },
    suggestionsApiUrl: {
      type: String
      // required: true <-- Add in common-ui version
    },
    suggestionsApiDebounce: {
      type: Number,
      default: 500
    },
    maxSuggestions: {
      type: Number,
      default: 10
    },
    includePoBoxes: {
      type: Boolean,
      default: false
    },
    addressInfoApiUrl: {
      type: String
      // required: true <-- Add in common-ui version
    },
    minLengthForSearch: {
      type: Number,
      default: 4
    },
    noMatchingText: {
      type: String
    },
    itemText: {
      type: String,
      default: 'address'
    }
  },
  data() {
    return {
      selectedAddress: null,
      optionsList: [],
      searchInput: ''
    }
  },
  computed: {
    attrs() {
      const defaultProps = {
        attach: `#${this.menuAnchorID}`,
        'append-icon': null,
        clearable: true,
        'hide-no-data': true,
        outlined: true,
        'return-object': true,
        'prepend-inner-icon': 'search',
        'no-filter': true
      }
      return { ...defaultProps, ...this.$attrs }
    },
    menuAnchorID() {
      // eslint-disable-next-line no-underscore-dangle
      return `address-menu-anchor_${this._uid}`
    },
    noOfSuggestionsMsg() {
      const numOptions = this.optionsList?.length
      return Number.isInteger(numOptions)
        ? `There are ${numOptions} results to choose from`
        : ''
    }
  },
  watch: {
    searchInput(val) {
      if (!val) {
        this.optionsList = []
        return
      }
      const selectAddress = this.selectedAddress?.address
      // Remove multiple instances of whitespace, but preserve spaces in the address sent to the API
      const sanitisedVal = this.sanitise(val)
      if (
        sanitisedVal.trim().length >= this.minLengthForSearch &&
        val !== selectAddress
      ) {
        this.debounce(
          () =>
            this.handleSuggestions(
              this.suggestionsApiUrl,
              sanitisedVal,
              this.maxSuggestions,
              this.includePoBoxes,
              this.noMatchingText
            ),
          this.suggestionsApiDebounce
        )
      }
    }
  },
  async mounted() {
    // Handle pre-populating the address
    if (this.preFilledAddress) {
      let address = this.sanitise(this.preFilledAddress)
      if (address.trim().length >= this.minLengthForSearch) {
        const suggestions = await this.getSuggestions(
          this.suggestionsApiUrl,
          address,
          this.maxSuggestions,
          this.includePoBoxes
        )
        if (suggestions && suggestions[0]) {
          address = suggestions[0].address
          await this.handleSuggestionSelection(suggestions[0])
        }
      }
      // eslint-disable-next-line no-return-assign
      this.$nextTick(() => (this.searchInput = address))
    }
  },
  methods: {
    capitalise(string) {
      return string
        .toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ')
    },
    sanitise(val) {
      return val.replace(/^\s+/, '').replace(/\s+/g, ' ')
    },
    debounce(callback, duration) {
      clearTimeout(this.debounceTimerID)
      this.debounceTimerID = setTimeout(() => {
        callback()
      }, duration)
    },
    async getSuggestions(url, address, maxSuggestions, includePoBoxes) {
      this.$emit(EVENTS.LOADING_SUGGESTIONS, true)
      try {
        const { data } = await oesAddressFinderAPI.getAddressSuggestions(
          url,
          address,
          maxSuggestions,
          includePoBoxes
        )
        return data
      } catch {
        this.$emit(EVENTS.LOADING_SUGGESTIONS, false, true)
        return false
      } finally {
        this.$emit(EVENTS.LOADING_SUGGESTIONS, false)
      }
    },
    populateSuggestionsList(suggestions, noMatchingText) {
      const uniqueSuggestions = _uniqBy(
        suggestions,
        (suggestion) => suggestion.address
      )
      if (Array.isArray(uniqueSuggestions) && uniqueSuggestions.length !== 0) {
        this.optionsList = uniqueSuggestions
      } else if (noMatchingText) {
        this.optionsList = [{ [this.itemText]: noMatchingText, disabled: true }]
      }
      this.$emit(EVENTS.UPDATE_OPTIONS, this.optionsList)
    },
    async getFullAddressInfo(url, addressID) {
      this.$emit(EVENTS.LOADING_SELECTION, true)
      try {
        const apiResponse = await oesAddressFinderAPI.getFullAddressInfo(
          url,
          addressID
        )
        const { addressDetails, addressId, geo } = apiResponse.data.data
        // eslint-disable-next-line max-len
        const isAustralianState = AUSTRALIAN_STATES.includes(
          addressDetails.stateTerritory
        )
        return {
          addressLine1: this.capitalise(
            addressDetails.formattedAddress.split(',')[0]
          ),
          suburbName: this.capitalise(addressDetails.localityName),
          postCode: addressDetails.postcode,
          stateCode: isAustralianState ? addressDetails.stateTerritory : 'XXX',
          countryCode: isAustralianState ? 'AUS' : 'XXX',
          addressId,
          longitude: geo.geometry.coordinates[0],
          latitude: geo.geometry.coordinates[1]
        }
      } finally {
        this.$emit(EVENTS.LOADING_SELECTION, false)
      }
    },
    async handleSuggestions(
      url,
      searchInput,
      maxSuggestions,
      includePoBoxes,
      noMatchingText
    ) {
      // eslint-disable-next-line max-len
      const suggestions = await this.getSuggestions(
        url,
        searchInput,
        maxSuggestions,
        includePoBoxes
      )
      this.populateSuggestionsList(suggestions, noMatchingText)
    },
    async handleSuggestionSelection(suggestion) {
      let addressObject
      if (suggestion && typeof suggestion === 'object') {
        this.selectedAddress = suggestion
        addressObject = await this.getFullAddressInfo(
          this.addressInfoApiUrl,
          suggestion.id
        )
      }
      this.$emit(EVENTS.UPDATE_ADDRESS, addressObject)
    }
  }
}
</script>

<style scoped lang="scss">
.menu-anchor {
  position: relative;
}
</style>
