<template>
  <Stepper
    ref="stepper"
    :steps="steps"
    :current-step="currentStepNum"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { Stepper } from '@nswdoe/doe-ui-core'
import { mapGetters } from 'vuex'
import stepper from '../mixins/stepper'

export default {
  name: 'Y67TStepper',
  components: {
    Stepper
  },
  mixins: [stepper],
  computed: {
    ...mapGetters(['steps'])
  }
}
</script>

<style lang="scss" scoped>
::v-deep .step {
  display: flex;
  align-items: center;
}

::v-deep .step-name {
  white-space: normal;
  line-height: 1.3;
  font-weight: 500;
}

::v-deep .selected .step-name {
  font-weight: bold;
}
</style>
