<template>
  <Modal v-bind="$attrs" v-on="$listeners">
    <div id="InProgressApplicationModal">
      <h1 class="heading">
        Proceeding will replace your in progress expression of interest. Do you
        still want to proceed?
      </h1>
      <p>
        You can only have one expression of interest in progress at a time. If
        you proceed, any saved progress for <strong>{{ studentName }}</strong
        >’s application for <strong>{{ schoolName }}</strong> will be lost. To
        prevent this, complete and submit their application first.
      </p>
      <div class="actions">
        <AdsButton
          class="adsButton mr-md-6"
          button-text="Cancel"
          secondary
          x-large
          @click="$emit('cancel')"
        />
        <AdsButton
          class="adsButton"
          button-text="Proceed anyway"
          x-large
          @click="$emit('proceed')"
        />
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal'
import { AdsButton } from '@nswdoe/doe-ui-core'

export default {
  name: 'InProgressApplicationModal',
  components: {
    Modal,
    AdsButton
  },
  props: {
    studentName: {
      type: String,
      default: ''
    },
    schoolName: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
#InProgressApplicationModal {
  font-weight: 500;
  margin: 0 -0.5rem -0.5rem -0.5rem;

  .heading {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1.25rem;
  }

  .actions {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: flex-end;
    margin-top: 2rem;

    & button {
      font-weight: bold;

      @include mobile {
        display: block;
        width: 100%;

        &:not(:last-child) {
          margin-top: 0.5rem;
        }
      }
    }
  }

  h1 {
    color: $ads-navy;
    font-weight: bold;
    font-size: 1.25rem;
  }

  p:last-child {
    margin: 0;
  }
}
</style>
