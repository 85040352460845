// TODO: Remove gender codes and scholastic years and replace with appropriate ref data /
// scholasticYear API once we start building out the forms!
import moment from 'moment'

export const GENDER_CODES = [
  { label: 'Male', value: 'M' },
  { label: 'Female', value: 'F' }
]

export const YES_NO = [
  { label: 'Yes', value: true },
  { label: 'No', value: false }
]

export const FIELD_TYPE = {
  ADDRESS: 'ADDRESS',
  CHECKBOX: 'CHECKBOX',
  CHECKBOX_GROUP: 'CHECKBOX_GROUP',
  COLLECTION: 'COLLECTION',
  DATE: 'DATE',
  DROPDOWN: 'DROPDOWN',
  EMAIL: 'EMAIL',
  GROUP: 'GROUP',
  HEADING: 'HEADING',
  LINKTO: 'LINKTO',
  LOOKUP: 'LOOKUP',
  MONTH_YEAR: 'MONTH_YEAR',
  NAME: 'NAME',
  PHONE: 'PHONE',
  POSTCODE: 'POSTCODE',
  RADIO: 'RADIO',
  TEXTAREA: 'TEXTAREA'
}

export const LOOKUP_STATE = {
  INITIAL: 'INITIAL',
  FILTERING: 'FILTERING',
  SELECTED: 'SELECTED'
}

export const TERM_DATES = [
  {
    text: 'Term One (2020)',
    value: '2020-01-29'
  },
  {
    text: 'Term Two (2020)',
    value: '2020-04-27'
  },
  {
    text: 'Term Three (2020)',
    value: '2020-07-20'
  },
  {
    text: 'Term Four (2020)',
    value: '2020-10-12'
  },
  {
    text: 'Term One (2021)',
    value: '2021-01-27'
  },
  {
    text: 'Term Two (2021)',
    value: '2021-04-19'
  },
  {
    text: 'Term Three (2021)',
    value: '2021-07-12'
  }
]

export const HEARTBEAT_EXEMPTIONS = [
  'ConfirmationView',
  'ErrorView',
  'LoginView',
  'PilotView',
  'WelcomeView'
]

export const ALERT_TYPE = {
  MISSING: 'MISSING',
  INVALID: 'INVALID',
  CONFLICT: 'CONFLICT'
}

export const ADDRESS_VALIDATION = {
  UNKNOWN: 'N',
  VALID: 'V'
}
export const ERROR_CODES = {
  NO_PENDING: 'NO_PENDING',
  NO_SCHOOL_CODE: 'NO_SCHOOL_CODE',
  NETWORK_ERROR: 'NETWORK_ERROR',
  API: 'API'
}

export const API_ERROR_SUBTYPES = {
  initiateAuth: 'PI67IAR',
  authenticate: 'PI67AU',
  submitApplication: 'PI67SU',
  fetchApplication: 'PI67RE',
  saveApplication: 'PI67SA',
  blockSubmitApplication: 'PI67EOIBLOCK'
}

export const ADDRESS_STATE = {
  INITIAL: 'INITIAL',
  WITHIN_AREA: 'WITHIN_AREA',
  OUT_OF_AREA: 'OUT_OF_AREA',
  NONE_FOUND: 'NONE_FOUND',
  CONFIRMING_CATCHMENT: 'CONFIRMING_CATCHMENT',
  INPUTTING_ADDRESS: 'INPUTTING_ADDRESS'
}

export const REFDATA_MAP = {
  ABORIGINALITY: 'Aboriginality',
  CONTACT_TYPE: 'ContactType',
  COUNTRIES: 'Countries',
  COUNTRIES_POPULAR: 'CountriesPopular',
  DISABILITY: 'Disability',
  LANGUAGES: 'Languages',
  LANGUAGES_POPULAR: 'LanguagesPopular',
  LEARNING_ADJUSTMENTS: 'LearningAdjustments',
  MEDICAL_CONDITION_ALLERGIES: 'MedicalConditionAllergies',
  MEDICAL_CONDITION_NAME: 'MedicationConditionName',
  OCCUPATION_GROUP: 'OccupationGroup',
  PARENT_SCHOOL_EDUCATION: 'ParentSchoolEducation',
  PARENT_TERTIARY_EDUCATION: 'ParentTertiaryEducation',
  PRESCHOOL_HOURS_TYPE: 'PreschoolHoursType',
  PRESCHOOL_TYPE: 'PreschoolType',
  RELATIONSHIP: 'Relationship',
  RESIDENCY_STATUS: 'ResidencyStatus',
  TITLE: 'Title',
  STATES: 'States',
  VISA_SUB_CLASS: 'VisaSubClass',
  TEMP_VISA_SUBCLASS: 'TempVisaSubClass'
}

// Default api date format
export const DATE_FORMAT = 'YYYY-MM-DD'

// Used as the value for intendedStartDate when enterStartDate is specified
export const CHOOSE_DATE = 'Choose a specific start date'

// School terms dropdown vlaues as per the system date
const now = Date.now()

const nextFourTerms = TERM_DATES.filter((item) => {
  const ms = Date.parse(item.value)

  // Filter out the terms that in the past
  if (!Number.isNaN(ms) && ms <= now) {
    return false
  }
  return true
}).slice(0, 4)

export const SCHOOL_TERMS = [
  ...nextFourTerms,
  {
    text: 'Choose a specific start date',
    value: CHOOSE_DATE
  }
]

// scholastic year primary/secondary values
export const SCHOLASTIC_YEARS_BY_CATCHMENT_LEVEL = {
  PRIMARY: ['P', 'K', '1', '2', '3', '4', '5', '6'],
  SECONDARY: ['7', '8', '9', '10', '11', '12']
}

export const URL_CATCHMENT_LEVELS = ['primary', 'secondary']

export function getStartYear(form) {
  // Gets start year from intendedStartDate (or enterStartDate if manual date is entered).
  // A year will only be returned if the whole date is valid.
  try {
    const startDate =
      form.intendedStartDate === CHOOSE_DATE
        ? moment(form.enterStartDate, 'DD-MM-YYYY').format('YYYY-MM-DD')
        : form.intendedStartDate
    const date = new Date(startDate)
    return startDate.length === 10 ? date.getFullYear() : null
  } catch (e) {
    return null
  }
}

export const NODE_ENVIRONMENTS = {
  LOCAL: 'LOCAL',
  DEV: 'DEV',
  TEST: 'TEST',
  PREPROD: 'PRE',
  PROD: 'PROD',
  TRAIN: 'TRAIN'
}

export const CONTACT_PHONE_TYPE = {
  PHMOB: 'Mobile',
  PHHOM: 'Home',
  PHWRK: 'Business/Work'
}

export const CATCHMENT_LEVELS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  CENTRAL: 'central',
  SSP: 'SSP'
}

export const SCHOOL_SELECTIVENESS = {
  NOT: 'Not',
  PARTIALLY: 'Partially',
  FULLY: 'Fully'
}

export const SCHOOL_CAPACITY = {
  UNDER: 'Under',
  OVER: 'Over',
  APPROACH: 'Approach',
  NA: 'NA'
}

export const OOA_CRITERIA = {
  SIB: 'SIB',
  LAW: 'LAW',
  MED: 'MED'
}

export const AUTH_SOURCE = {
  OTP: 'OTP',
  SNSW: 'SNSW'
}

// Currently non-exhaustive. Just referencing SOME route names that are used throughout the app.
export const ROUTE_NAMES = {
  FORM: 'form',
  LOGOUT: 'logout',
  CONFIRMATION: 'confirmation',
  ERROR: 'error',
  TIMEOUT: 'timeout',
  NOAPPLICATION: 'noapplication'
}

export const SCHOOL_SUBTYPE = [
  'Kindergarten to Year 12',
  'Kinder to Year 12',
  'Year 7 to Year 10',
  'Year 7 to Year 12',
  'Year 7 to Year 9'
]
