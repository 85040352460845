import axios from 'axios'
import store from '@/store'
import { API_ERROR_SUBTYPES } from '@/constants'

const API_BASE = process.env.VUE_APP_API_BASE

const APPLICATION_URL = `${API_BASE}/v1/ooa/applications/saved/self`

function fetchApplication() {
  return axios
    .get(APPLICATION_URL, { params: { stream: 'y67t' } })
    .then((response) => response?.data?.body?.application)
    .catch((error) => {
      store.commit('setError', error)
      throw error
    })
}

function saveApplication(application, isNew = false) {
  axios
    .put(`${API_BASE}/v1/ooa/applications/saved/self`, { application, isNew })
    .then((response) => response.data.body)
    .catch((error) => {
      store.dispatch('setAPIError', {
        error,
        fallbackCode: API_ERROR_SUBTYPES.saveApplication
      })
      throw error
    })
}

function createApplication(application) {
  saveApplication(application, true)
}

function submitApplication(applicationData) {
  const APPLICATION_URL = `${API_BASE}/v1/y67t/applications/submitted/self`
  const data = { application: applicationData }
  return axios
    .post(APPLICATION_URL, data)
    .then((response) => response.data.body)
    .catch((error) => {
      store.commit('setError', error)
      throw error
    })
}

function submitAdvice(applicationData) {
  const APPLICATION_URL = `${API_BASE}/v1/y67t/advice/self`
  const data = { application: applicationData }
  return axios
    .post(APPLICATION_URL, data)
    .then((response) => response.data.body)
    .catch((error) => {
      store.commit('setError', error)
      throw error
    })
}

export default {
  createApplication,
  saveApplication,
  fetchApplication,
  submitApplication,
  submitAdvice
}
