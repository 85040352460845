// Can only contain letters, spaces, and - ( ) '
function isBasicTextOnly(text) {
  return !text || /^[a-zA-Z ()'-]+$/.test(text)
}

function isBelowMaxCharacter(text, maxCharacters = 128) {
  return !text || text.length <= maxCharacters
}

function isValidDateFormat(format, date) {
  let regPattern
  if (format === 'DD-MM-YYYY') {
    regPattern = /^([0-2][0-9]|(3)[0-1])(-)(((0)[0-9])|((1)[0-2]))(-)\d{4}$/
  } else if (format === 'YYYY-MM-DD') {
    regPattern = /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/
  } else if (format === 'YYYY-MM') {
    regPattern = /^(\d{4}-(0[1-9]|1[012]))$/
  } else if (format === 'MM-YYYY') {
    regPattern = /^(((0)[0-9])|((1)[0-2]))(-)\d{4}$/
  }
  return regPattern.test(date)
}

function isValidEmail(email) {
  return (
    !email ||
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    )
  )
}

function isValidPhoneNumber(phone) {
  return !phone || /^[0-9 ()\\-]+$/.test(phone)
}

export {
  isBasicTextOnly,
  isBelowMaxCharacter,
  isValidDateFormat,
  isValidEmail,
  isValidPhoneNumber
}
