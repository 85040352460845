// Taken from https://community.shopify.com/c/Shopify-Design/Ordinal-Number-in-javascript-1st-2nd-3rd-4th/m-p/72156
// eslint-disable-next-line import/prefer-default-export
export function numToOrdinal(number) {
  const suffixes = ['th', 'st', 'nd', 'rd']
  const v = number % 100
  const suffix = suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]
  return `${number}${suffix}`
}

export function getCognitoLogoutUrl() {
  const cognitoLogoutUrl = process.env.VUE_APP_COGNITO_LOGOUT_URI
  const clientId = process.env.VUE_APP_SNSW_AUTH_CLIENT_ID
  const cognitoCallBackUrl = `${window.location.protocol}//${window.location.host}/logout`
  return `${cognitoLogoutUrl}?client_id=${clientId}&logout_uri=${cognitoCallBackUrl}`
}

export function getSnswAccountUrl() {
  const snswAccountUrl = process.env.VUE_APP_SNSW_ACCOUNT_URI
  if (snswAccountUrl) {
    return snswAccountUrl
  }
  return ''
}
