import preApp from '@/pre-application-base'

/* eslint-disable no-param-reassign */
const preApplicationModule = {
  state: { ...preApp },
  getters: {
    residentialAddress: (state) => state.residentialAddress,
    acceptPrivacyAgreement: (state) => state.acceptPrivacyAgreement,
    preApplicationData: (state) => state
  },
  mutations: {
    setResidentialAddress(state, address) {
      state.residentialAddress = address
    },
    setAcceptPrivacyAgreement(state, value) {
      state.acceptPrivacyAgreement = value
    },
    setPreApplicationData(state, value) {
      Object.assign(state, value)
    },
    resetPreApplication(state) {
      Object.assign(state, preApp)
    }
  },
  actions: {
    setResidentialAddress({ commit }, { address }) {
      commit('setResidentialAddress', address)
    }
  }
}

export default preApplicationModule
