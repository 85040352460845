<template>
  <div>
    <Banner :image-src="require('@/assets/icon-school-alt.svg')">
      <template #heading>
        <h1 class="banner__heading">
          Welcome back,
          <br />
          {{ email }}
        </h1>
      </template>
    </Banner>
    <v-container fluid class="centeredContent text-center">
      <v-btn
        v-if="email && !isBlockEoiProceed"
        class="resume-button primary mt-12 mb-12"
        x-large
        data-testid="btn-resumeApplication"
        @click="resumeApplication"
      >
        Resume application
      </v-btn>
      <p v-if="isBlockEoiProceed" class="eoiClosedInfo">
        Expressions of interest for {{ nextYear }} are now closed
      </p>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Banner from '@/components/Banner'
import base64url from 'base64url'

export default {
  name: 'WelcomeBack',
  components: {
    Banner
  },
  beforeRouteEnter(to, from, next) {
    // eslint-disable-next-line no-unused-expressions
    new URLSearchParams(window.location.search).get('userId')
      ? next()
      : next({ name: 'login' })
  },
  computed: {
    ...mapGetters(['isBlockEoiProceed']),
    email() {
      return this.$route.query.userId
        ? base64url.decode(this.$route.query.userId)
        : ''
    },
    nextYear() {
      return new Date().getFullYear() + 1
    }
  },
  methods: {
    resumeApplication() {
      this.$store.commit('setIsResuming', true)
      this.$router.push({
        name: 'login',
        params: { resumeViaEmail: true, userEmail: this.email }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.eoiClosedInfo {
  margin-top: 5rem;
  font-size: 24px;
  color: $ads-primary-blue;
}
</style>
