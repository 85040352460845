import axios from 'axios'

// Environment variables
const API_BASE = process.env.VUE_APP_API_BASE

const getAddressSuggestions = (
  url = `${API_BASE}/nswpoint/v2/api/predictive1`,
  address,
  numSuggestions = 10,
  includePoBoxes = false
) =>
  axios.get(url, {
    params: {
      address,
      maxNumberOfResults: numSuggestions,
      ...(includePoBoxes ? {} : { addressType: 'physical' }) // 'physical' prevents looking up PO boxes
    },
    headers: {
      'x-preflight': 'force'
    }
  })

const getFullAddressInfo = (
  url = `${API_BASE}/nswpoint/v2/api/predictive2`,
  addressID
) =>
  axios.post(
    url,
    { id: addressID },
    {
      headers: { 'x-preflight': 'force' }
    }
  )

export default {
  getAddressSuggestions,
  getFullAddressInfo
}
