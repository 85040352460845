<template>
  <v-row v-if="showNotification">
    <v-col md="12" class="mb-6">
      <v-alert
        border="left"
        colored-border
        color="#DC5800"
        icon="mdi-alert"
        class="mb-0"
      >
        <p class="bannerHeading font-weight-bold mb-0">
          {{ notification.heading }}
        </p>
        <p>{{ notification.body }}</p>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'

export default {
  name: 'OutageNotification',
  computed: {
    oesProperties() {
      return this.$store.getters.oesProperties
    },
    notification() {
      return this.oesProperties && this.oesProperties.banner
        ? this.oesProperties.banner.y67
        : null
    },
    showNotification() {
      if (
        !this.notification ||
        !this.notification.displayStartTime ||
        !this.notification.displayEndTime
      ) {
        return false
      }

      const now = moment().valueOf()
      const startTime = moment(
        this.notification.displayStartTime,
        'DD/MM/YYYY HH:mm:ss'
      ).valueOf()
      const endTime = moment(
        this.notification.displayEndTime,
        'DD/MM/YYYY HH:mm:ss'
      ).valueOf()
      return now >= startTime && now <= endTime
    }
  }
}
</script>

<style lang="scss" scoped>
.bannerHeading {
  color: $ads-dark;
}
</style>
