<template>
  <Modal v-bind="$attrs" v-on="$listeners">
    <div id="PrivacyAgreementMdl">
      <h1>Department of Education Online Enrolment Privacy Notice</h1>
      <p>
        As part of the NSW Department of Education's online enrolment process we
        collect and store your personal information. Please ensure you read the
        following:
      </p>
      <h2>
        Why do we collect information about your child for enrolment and how do
        we store it?
      </h2>
      <p>
        The personal information collected on this application is for purposes
        directly related to your child's education including processing this
        application.
      </p>
      <p>
        Any information provided to the NSW Department of Education (the
        Department) will be used, disclosed and stored consistent with the NSW
        privacy laws.
      </p>
      <p>
        Certain information is required by the Department to meet its duty of
        care and other legal obligations and functions under public health,
        education and child protection legislation and for meeting data
        collection and reporting requirements under Commonwealth – State funding
        agreements. These may involve evaluation and assessment of student
        outcomes.
      </p>
      <p>
        Information may be disclosed to NSW State and Commonwealth government
        agencies and other organisations for the above purposes and as
        authorised or required by law.
      </p>
      <p>
        Information will be stored on a secure electronic database. You may
        access or correct the information by contacting your child's school. If
        you have a concern or complaint about the information collected or how
        it has been used or disclosed you should contact the school.
      </p>
      <p>
        If you choose not to provide some requested information it may have a
        detrimental impact on your child's enrolment, resourcing of the school
        or meeting your child's educational needs.
      </p>
      <p>
        Further information about the collection of information while your child
        is enrolled at a NSW Government school, and how we protect your privacy
        is available on the Department's website or from your school.
      </p>
      <p>
        After submitting your application, if you need to access or correct any
        personal or health information submitted you should contact the school
        where you have applied to enrol your child.
      </p>
      <p>
        Further details setting out how the Department will treat the personal
        information and health information submitted as part of school enrolment
        processes (including permitted use and disclosure) is contained in:
      </p>
      <ul class="linkPoint">
        <li>
          <a
            :href="dataCollectionURL"
            target="_blank"
            data-testid="dataCollectionNotice"
          >
            Privacy Collection Notice
          </a>
        </li>
        <li>
          <a
            :href="privacyCodeURL"
            target="_blank"
            data-testid="privacyCodeofPractice"
          >
            Privacy Code of Practice
          </a>
        </li>
        <li>
          <a
            :href="privacyPlanURL"
            target="_blank"
            data-testid="privacyManagementPlan"
          >
            Privacy Management Plan
          </a>
        </li>
      </ul>
      <h2>What are we doing with information you provide to us online?</h2>
      <p>
        Online enrolment usage data is collected and analysed to help make this
        a better product into the future, making it easier for parents to enrol
        their children in our schools. Examples of the usage data collected on
        each page of this site are:
      </p>
      <ul class="contentPoint">
        <li>
          the IP (Internet Protocol) address of the machine which has accessed
          it
        </li>
        <li>
          your top-level domain name (for example .com, .gov, .au, .uk etc.)
        </li>
        <li>the address of your server</li>
        <li>the date and time of your visit to the site</li>
        <li>the pages accessed and documents downloaded</li>
        <li>the previous site visited and</li>
        <li>the type of browser and operating system you have used.</li>
      </ul>
      <p>
        If you do not want us to collect this online information then you can
        complete a hard copy application to enrol available to download from our
        <a :href="goToSchoolUrl" target="_blank"
          >going to a public school website</a
        >.
      </p>
      <p>
        At the end of the application process you will be asked to declare the
        accuracy of the information and submit. On submitting your application
        you will receive an email containing a password protected copy of your
        application.
      </p>
      <p>
        The school you apply to enrol at will also receive a copy of this email.
        When you contact the school, they will advise you about which documents
        you will need to bring with you to the school.
      </p>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal'
import { mapGetters } from 'vuex'

export default {
  name: 'PrivacyAgreementModal',
  components: {
    Modal
  },
  computed: {
    ...mapGetters(['privacyLinks']),
    dataCollectionURL() {
      return this.privacyLinks?.dataCollectionNotice
    },
    privacyCodeURL() {
      return this.privacyLinks?.privacyCodeofPractice
    },
    privacyPlanURL() {
      return this.privacyLinks?.privacyManagementPlan
    },
    goToSchoolUrl() {
      return this.privacyLinks?.paperbaseEnrolmentForm
    }
  }
}
</script>

<style lang="scss" scoped>
#PrivacyAgreementMdl {
  h1 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    color: $ads-primary-blue;
  }

  a {
    color: $ads-primary-blue;
  }

  h2 {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
    color: $ads-primary-blue;
    line-height: 1.5rem;
  }

  p,
  .contentPoint {
    font-size: 1rem;
    margin-bottom: 1.5rem;
    color: $ads-grey-01;
  }

  .linkPoint {
    font-size: 1rem;
    margin-bottom: 1.5rem;
    color: $ads-primary-blue;
  }

  li {
    margin-left: -0.5em;
    font-weight: 500;

    &::marker {
      font-size: 0.75em;
    }
  }
}
</style>
