<template>
  <footer class="footer">
    <v-container class="centeredContent">
      <v-row class="footer__content">
        <v-col class="footer-logo">
          <img
            class="logo"
            src="@/assets/logo.svg"
            alt="New South Wales Department of Education"
          />
        </v-col>
        <v-col class="links">
          <div class="links__privacy">
            <h4 class="links__title">Privacy</h4>
            <ul class="links__list">
              <li>
                <a
                  href="https://education.nsw.gov.au/about-us/rights-and-accountability/privacy/privacy-legislation"
                  target="_blank"
                  data-testid="links-privacy"
                >
                  <v-icon class="list-link__icon">mdi-lock-outline</v-icon
                  >Privacy Legislation
                  <span class="sr-only">this link will open in a new tab</span>
                </a>
              </li>
            </ul>
          </div>
          <div class="links__support" data-testid="links-support">
            <h4 class="links__title">Technical Support</h4>
            <ul class="links__list">
              <li>
                <a
                  href="https://enrolmentassist.freshdesk.com/support/tickets/new"
                  target="_blank"
                >
                  <v-icon class="list-link__icon">description</v-icon>Use our
                  contact form
                  <span class="sr-only">this link will open in a new tab</span>
                </a>
              </li>
              <li>
                <a :href="`tel:${dialPhone}`">
                  <v-icon class="list-link__icon">phone_iphone</v-icon>Phone
                  {{ phone }}
                </a>
                <span class="list-link__additional-info">
                  {{ hours }}
                </span>
              </li>
            </ul>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AppFooter',

  computed: {
    ...mapGetters(['oesProperties']),
    hours() {
      return this.oesProperties?.footer?.support?.phone?.hours || ''
    },
    phone() {
      return this.oesProperties?.footer?.support?.phone?.number || ''
    },
    dialPhone() {
      return this.phone.replace(/\s/g, '')
    }
  }
}
</script>

<style scoped lang="scss">
.footer {
  background-color: $ads-navy;
  height: 190px;
  display: flex;
  align-items: center;

  .footer-logo {
    flex-grow: 0;
    margin: 0;
    min-width: 64px;
    align-self: center;
    padding-left: 0;

    .logo {
      width: 7rem;
    }
  }

  .links {
    display: flex;
    justify-content: flex-end;
    color: $ads-white;
    font-size: 1rem;
    text-decoration: none;

    > :not(:last-child) {
      margin-right: 5rem;
    }

    &__title {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 1rem;
      line-height: 1.5;
      padding-bottom: 1rem;
    }

    &__list {
      margin-bottom: 0;

      > :not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }

  ul {
    list-style: none;
    padding: 0;
  }

  .list-link {
    display: flex;
    align-items: center;

    &__icon {
      margin-right: 1rem;
      color: $ads-white;
    }

    &__additional-info {
      padding-left: 2.5rem;
      display: block;
    }
  }

  a {
    text-decoration: none;
    color: $ads-white;
    font-size: 1rem;
    font-weight: 500;
  }

  @include tablet {
    height: auto;
    .footer-logo {
      margin-right: 1.5rem;
    }
    .links > :not(:last-child) {
      margin-right: 2rem;
    }
  }

  @include mobile-lg {
    .row {
      display: block;
    }
    .links {
      display: block;
      &__privacy,
      &__support {
        margin-bottom: 1.25rem;
      }
    }
  }
}
</style>
