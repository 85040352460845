export default {
  props: {
    label: {
      type: String
    },
    rules: {
      type: Array,
      default() {
        return []
      }
    },
    required: [Boolean, String]
  },
  computed: {
    internalRules() {
      const labels = {
        phoneNumberLabelValue: 'Phone number is required',
        genderLabelValue: "Student's gender is required",
        selectiveSchoolLabelValue:
          'Please choose if you have submitted an application for placement in an academically selective school',
        ooAGovtSchoolsLabelValue:
          'Please choose if you will be considering placement(s) at any other government schools besides your local high school',
        preferredLocalSchoolLabelValue:
          'Please select your preferred local school'
      }

      let message =
        typeof this.required === 'string'
          ? this.required
          : `${this.label} is required`
      if (
        this.label ===
        'Have you submitted an application for placement in an academically selective school?'
      ) {
        message = labels.selectiveSchoolLabelValue
      } else if (
        this.label ===
        'Will you be considering placement(s) at any other government schools besides your local high school?'
      ) {
        message = labels.ooAGovtSchoolsLabelValue
      } else if (this.label === 'Enter number') {
        message = labels.phoneNumberLabelValue
      } else if (this.label && this.label.includes('gender')) {
        message = labels.genderLabelValue
      } else if (
        this.label &&
        this.label.includes('Please select your preferred local school')
      ) {
        message = labels.preferredLocalSchoolLabelValue
      }

      return this.required
        ? [...this.rules, (v) => (v != null && v !== '') || message]
        : [...this.rules]
    }
  }
}
