export default {
  methods: {
    capitalise(string) {
      return string
        .toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ')
    },
    addressObjectToString(addressObject, delimiter) {
      return this.addressComponentsToString(
        addressObject?.addressLine1,
        addressObject?.suburbName,
        addressObject?.stateCode,
        addressObject?.postCode,
        delimiter
      )
    },
    addressComponentsToString(
      streetAddress,
      suburb,
      state,
      postCode,
      delimiter = ' '
    ) {
      const addressSection = streetAddress
        ? `${streetAddress.trim()}${delimiter}`
        : ''
      const suburbSection = suburb
        ? `${this.capitalise(suburb).trim()}${delimiter}`
        : ''
      const stateSection = state ? `${state.toUpperCase().trim()} ` : ''
      const postCodeSection = postCode || ''
      return `${addressSection}${suburbSection}${stateSection}${postCodeSection}`.trim()
    }
  }
}
