<template>
  <!-- eslint-disable max-len -->
  <div>
    <Banner
      :image-src="require('@/assets/icon-school-alt.svg')"
      :heading="heading"
      sub-heading=""
    />
    <v-container fluid class="centeredContent py-12">
      <ContentBox
        v-if="isPNRAdvice"
        class="pt-6 applicationId"
        img-file-name="green_tick.svg"
        data-testid="application-submitted"
      >
        <div slot="title">
          <h2 class="pb-4">
            We have advised your primary school that you do not require a
            placement for {{ studentName }} at a government high school. <br />
            <span class="plainText">Your reference number is:</span>
            <strong data-testid="application-id">{{ applicationId }}</strong>
          </h2>
        </div>
        <div slot="copyContent">
          <p>
            We’ve sent a copy to your email at
            <strong data-testid="email-address">{{ email }}</strong>
          </p>
        </div>
      </ContentBox>
      <ContentBox
        v-else
        class="pt-6 applicationId"
        img-file-name="green_tick.svg"
        data-testid="application-submitted"
      >
        <div slot="title">
          <h2 class="pb-4">
            You've submitted your expression of interest<br />
            <span class="plainText">Your reference number is:</span>
            <strong data-testid="application-id">{{ applicationId }}</strong>
          </h2>
        </div>
        <div slot="copyContent">
          <p>
            We’ve sent a copy of your expression of interest and reference
            number to <strong data-testid="email-address">{{ email }}</strong>
          </p>
        </div>
      </ContentBox>
      <v-divider />
      <ContentBox
        class="pt-9"
        img-file-name="whatsnext.svg"
        title="What's next?"
        image-class="largeImage"
        data-testid="email-icon"
      >
        <div slot="copyContent">
          <p>
            Please check your inbox for a confirmation email. The email will
            include a copy of
            <span v-if="isPNRAdvice">the information you have sent</span
            ><span v-else>your expression of interest</span>, a reference number
            and the next steps.
          </p>
          <p v-if="!isPNRAdvice">
            If a high school needs additional information they will contact you
            directly.
          </p>
        </div>
      </ContentBox>
      <ContentBox
        img-file-name="survey-icon.svg"
        title="Complete our 1 minute survey"
        image-class="largeImage"
        data-testid="survey"
      >
        <div slot="copyContent">
          <p>
            Our goal is to create the best possible product, and your thoughts,
            ideas, and suggestions play a major role in helping us identify
            opportunities to improve.<br />
            <strong
              ><a href="https://www.surveymonkey.com/r/G2DK39K" target="_blank"
                >Click here to start.
                <span class="sr-only">this link will open in a new tab</span></a
              ></strong
            >
          </p>
        </div>
      </ContentBox>
    </v-container>
  </div>
</template>

<script>
import Banner from '@/components/Banner'
import ContentBox from '@/components/ContentBox'
import { mapGetters } from 'vuex'

export default {
  name: 'AppConfirmation',
  components: {
    Banner,
    ContentBox
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['isPNRAdvice', 'applicationId', 'email', 'pnrApplication']),
    heading() {
      if (this.isPNRAdvice) {
        return 'Your advice has been sent'
      }
      return 'Your expression of interest has been submitted'
    },
    studentName() {
      if (this.pnrApplication?.student) {
        return `${this.pnrApplication.student.firstName} ${this.pnrApplication.student.familyName}`
      }
      return ''
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.plainText {
  font-weight: normal;
}
.applicationId {
  word-break: break-word;
}
</style>
