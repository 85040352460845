<!-- eslint-disable max-len -->
<!-- eslint-disable -->
<template>
  <v-form
    class="pa-6 white-card"
    data-testid="login-email-form"
    @submit.prevent="handleSubmit"
  >
    <v-row>
      <v-col cols="12" md="3" class="text-center">
        <img
          v-if="imageSrc"
          class="email-login__image mt-4"
          data-testid="email-login-img"
          :src="imageSrc"
          :alt="imageAlt"
        />
      </v-col>
      <v-col cols="12" md="9">
        <v-row>
          <v-col>
            <h3 class="email-login__heading mb-2">
              <slot name="heading">
                {{ heading }}
              </slot>
            </h3>
            <p class="email-login__text">
              <slot name="text" />
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              ref="email"
              v-model="internalEmail"
              :readonly="readonly"
              :class="{ 'readonly-text': readonly }"
              :error-messages="errorMessages"
              :rules="computedRules"
              data-testid="email-login-text-input"
              v-bind="textAttributes"
              v-on="$listeners"
              persistent-placeholder
            >
              <!-- Passes all slots/scopedSlots passed in
               to this component down into v-text-field -->
              <template v-for="(_, slot) of scopedSlots" v-slot:[slot]="scope">
                <slot :name="slot" v-bind="scope" />
              </template>
              <template v-for="(_, name) in $slots" v-slot:[name]>
                <slot :name="name" />
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="includeRecaptcha && recaptchaSiteKey">
          <v-col>
            <vue-recaptcha
              ref="recaptcha"
              data-testid="email-login-recaptcha"
              :sitekey="recaptchaSiteKey"
              v-bind="recaptchaAttrs"
              @verify="handleRecaptchaVerify"
              @expired="handleRecaptchaExpired"
            />
          </v-col>
        </v-row>
        <v-row v-if="isPnrFlow">
          <v-col>
            <v-checkbox
              v-model="acceptTnc"
              class="login-card__tnc"
              data-testid="email-privacy-check"
            >
              <template v-slot:label>
                I have read and agree to the
                <a
                  tabindex="0"
                  ref="privacy-2"
                  @click="$emit('showPrivacy')"
                  @keyup.enter="$emit('showPrivacy')"
                  >privacy agreement</a
                >
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer />
      <v-col
        class="text-center"
        cols="12"
        md="2"
        sm="3"
        data-testid="email-login__button"
      >
        <slot name="button">
          <AdsButton
            class="primary email-login__button"
            data-testid="submit-button"
            v-bind="buttonAttributes"
            :button-text="buttonText"
            :loading="loading"
            :disabled="disableContinue"
            @click="handleSubmit"
          >
            <template v-slot:loader>
              <v-progress-circular
                class="icon spin button__spinner"
                :size="20"
                :width="4"
                color="white"
                indeterminate
                aria-label="loading"
              />
              {{ buttonText }}
            </template>
          </AdsButton>
        </slot>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
import authApi from 'api-client/auth'
import { AdsButton } from '@nswdoe/doe-ui-core'

const EVENTS = {
  UPDATE_EMAIL: 'update:email',
  UPDATE_EMAIL_ERROR: 'update:emailError',
  UPDATE_RECAPTCHA_ERROR: 'update:recaptchaError',
  UPDATE_LOADING: 'update:loading',
  AUTH_INITIATED: 'authInitiated'
}

const recaptchaTestOverride = '111111'

export default {
  name: 'OesEmailLogin',
  components: {
    VueRecaptcha,
    AdsButton
  },
  inheritAttrs: false,
  props: {
    heading: {
      type: String,
      default: 'Enter your email address'
    },
    isPnrFlow: {
      type: Boolean,
      default: false
    },
    buttonText: {
      type: String,
      default: 'Continue'
    },
    imageSrc: {
      type: String
    },
    imageAlt: {
      type: String
    },
    includeRecaptcha: {
      type: Boolean,
      default: true
    },
    recaptchaSiteKey: {
      type: String
    },
    recaptchaAttrs: {
      type: Object,
      default: () => {}
    },
    buttonAttrs: {
      type: Object,
      default: () => {}
    },
    readonly: {
      type: Boolean,
      default: false
    },
    initiateAuthApiUrl: {
      type: String
      // required: true <-- Add in common-ui version
    },
    email: {
      type: String
    },
    emailError: {
      type: String
    },
    recaptchaError: {
      type: String
    },
    loading: {
      type: Boolean
    }
  },
  data() {
    return {
      acceptTnc: false,
      recaptchaToken: null,
      dataEmail: this.email,
      dataEmailError: this.emailError,
      dataRecaptchaError: this.recaptchaError
    }
  },
  computed: {
    disableContinue() {
      if (this.isPnrFlow) {
        return (
          !(this.isValidEmail(this.internalEmail) && this.acceptTnc) ||
          (this.recaptchaToken === null &&
            this.includeRecaptcha &&
            this.recaptchaSiteKey !== null)
        )
      }
      return (
        !this.isValidEmail(this.internalEmail) ||
        (this.recaptchaToken === null &&
          this.includeRecaptcha &&
          this.recaptchaSiteKey !== null)
      )
    },
    computedRules() {
      const myRules = []
      if (!this.internalEmail) {
        myRules.push('Field is required')
      }
      if (!this.isValidEmail(this.internalEmail)) {
        myRules.push('Please enter a valid email address')
      }
      return myRules
    },
    internalEmail: {
      get() {
        return this.dataEmail
      },
      set(value) {
        this.dataEmail = value
        this.$emit(EVENTS.UPDATE_EMAIL, value)
      }
    },
    internalEmailError: {
      get() {
        return this.dataEmailError
      },
      set(value) {
        this.dataEmailError = value
        this.$emit(EVENTS.UPDATE_EMAIL_ERROR, value)
      }
    },
    internalRecaptchaError: {
      get() {
        return this.dataRecaptchaError
      },
      set(value) {
        this.dataRecaptchaError = value
        this.$emit(EVENTS.UPDATE_RECAPTCHA_ERROR, value)
      }
    },
    textAttributes() {
      const defaultAttrs = {
        label: 'Email address',
        placeholder: 'Enter your email address',
        outlined: true,
        'aria-required': true
      }
      return { ...defaultAttrs, ...this.$attrs }
    },
    buttonAttributes() {
      const defaultAttrs = {
        'x-large': true
      }
      return { ...defaultAttrs, ...this.buttonAttrs }
    },
    scopedSlots() {
      // eslint-disable-next-line no-unused-vars
      const { button, heading, text, ...rest } = this.$scopedSlots
      return rest
    },
    valid() {
      return !(this.errorMessages?.length > 0)
    },
    errorMessages() {
      return [this.internalEmailError, this.internalRecaptchaError].filter(
        (v) => v
      )
    }
  },
  watch: {
    email(value) {
      this.dataEmail = value
    },
    emailError(value) {
      this.dataEmailError = value
    },
    recaptchaError(value) {
      this.dataRecaptchaError = value
    },
    dataEmail(newValue, oldValue) {
      // Reset error flag when user changes email text
      if (newValue !== oldValue && this.internalEmailError !== null) {
        this.internalEmailError = null
      }
    },
    dataRecaptchaError(value) {
      // If there is a recaptcha error while there is also a recaptchaToken
      // then reset the recaptcha component and token
      if (value && this.recaptchaToken) {
        this.$refs.recaptcha.reset()
        this.recaptchaToken = null
      }
    }
  },
  methods: {
    isValidEmail(email) {
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    },
    validate() {
      if (!this.isValidEmail(this.internalEmail)) {
        this.internalEmailError = 'Please enter a valid email address'
        this.$refs.email.focus()
      }
      if (!this.recaptchaToken) {
        this.internalRecaptchaError = 'Please complete the reCAPTCHA'
      }
    },
    async getCognitoSession() {
      this.$emit(EVENTS.UPDATE_LOADING, true)
      try {
        return (
          await authApi.initiateAuth(
            this.initiateAuthApiUrl,
            this.internalEmail,
            this.recaptchaToken
          )
        )?.cognitoSession
      } finally {
        this.$emit(EVENTS.UPDATE_LOADING, false)
      }
    },
    async handleSubmit() {
      if (!this.includeRecaptcha) {
        // Hardcoded number for non-prod env's.
        this.recaptchaToken = recaptchaTestOverride
      }
      this.validate()
      // Normal flow
      if (this.valid && !this.isPnrFlow) {
        const cognitoSession = await this.getCognitoSession()
        this.$emit(EVENTS.AUTH_INITIATED, cognitoSession)
        // Google Analytics
        this.$gtm.trackEvent({
          event: 'interaction',
          category: 'Authentication',
          action: 'Attempt',
          label: 'Email'
        })
        // PNR Flow
      } else if (this.valid && this.isPnrFlow && this.acceptTnc) {
        const cognitoSession = await this.getCognitoSession()
        this.$emit(EVENTS.AUTH_INITIATED, cognitoSession)
        // Google Analytics
        this.$gtm.trackEvent({
          event: 'interaction',
          category: 'Authentication',
          action: 'Attempt',
          label: 'Email'
        })
      }
    },
    handleRecaptchaVerify(value) {
      this.internalRecaptchaError = null
      this.recaptchaToken = value
    },
    handleRecaptchaExpired() {
      this.recaptchaToken = null
    }
  }
}
</script>

<style lang="scss" scoped>
a:focus {
  border: 2px solid $ads-navy !important;
  outline-color: $ads-navy !important;
}

a {
  text-decoration: underline;
}

.email-login__heading,
.email-login__text {
  color: $color-text-body;
}

.email-login__button {
  width: 100%;
}

.button__spinner {
  margin-right: 0.25rem;
}

::v-deep .login-card__tnc.v-input--checkbox .v-label {
  display: inline-block;
  color: $color-text-body;
  font-weight: 500;
}
</style>
