<template>
  <transition name="slide-right">
    <div
      v-if="isVisible"
      class="EnvironmentInfo"
      aria-hidden="true"
      @dblclick="hide"
    >
      {{ versionNo && `Version: ${versionNo}` }}
      <br v-if="versionNo && environment" />
      {{ environment && `Env: ${environment}` }}
    </div>
  </transition>
</template>

<script>
import { NODE_ENVIRONMENTS } from '@/constants'

export default {
  name: 'EnvironmentInfo',
  data() {
    return {
      isVisible:
        process.env.VUE_APP_ENV_NAME &&
        process.env.VUE_APP_ENV_NAME !== NODE_ENVIRONMENTS.PROD,
      versionNo: process.env.VUE_APP_VERSION,
      envColour: process.env.VUE_APP_ENV_COLOUR,
      envName: process.env.VUE_APP_ENV_NAME
    }
  },
  computed: {
    environment() {
      if (this.envColour && this.envName) {
        return `${this.envColour}-${this.envName.toLowerCase()}`
      }
      if (this.envName) {
        return this.envName.toLowerCase()
      }
      return null
    }
  },
  methods: {
    hide() {
      this.isVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.EnvironmentInfo {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 500;

  font-size: 10px;
  line-height: 1.5;

  margin: 15px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(black, 0.1);
  background-color: white;
  color: $color-text-body;
}

.slide-right-leave-active {
  transition: all 0.6s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}
.slide-right-leave-to {
  transform: translateX(200%);
}
</style>
