import store from '@/store'

export default function form(to, from, next) {
  const { applicationId, school } = store.getters
  const schoolCode = school?.schoolCode || sessionStorage.getItem('schoolCode')

  if (!applicationId) {
    if (!schoolCode) {
      store.commit('resetError') // Clear any potential errors in store.
      next({ name: 'error' })
    } else {
      next({ name: 'home', query: { schoolCode } })
    }
  } else {
    next()
  }
}
