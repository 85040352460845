<template>
  <!-- eslint-disable max-len -->

  <div>
    <Banner heading="You are now signed out" />
    <v-container fluid class="centeredContent">
      <h2 v-if="!isSignOutFromPNRForm" class="pt-10 pb-12">
        Your expression of interest has been saved, and you can resume at any
        time.
      </h2>
      <section class="signout">
        <div class="signout__info" data-testid="resume-info">
          <v-avatar class="info__icon-wrapper" size="88" color="secondary">
            <v-icon
              color="primary"
              class="info__icon"
              size="60"
              alt="In progress applications"
            >
              restore
            </v-icon>
          </v-avatar>
          <p v-if="isSignOutFromPNRForm" class="info__text py-12">
            To start a new expression of interest, just go to the school website
            you used to start and choose <strong>Moving to high school.</strong>
          </p>
          <p v-else class="info__text">
            To resume your expression of interest later, just go to the school
            website you used to start, choose
            <strong>Moving to high school</strong> then select the
            <strong>Resume</strong> button. You can also follow the
            <strong>resume link</strong> sent to your email address
          </p>
        </div>
        <div class="signout__info" data-testid="snsw-info">
          <v-avatar class="info__icon-wrapper" size="88" color="secondary">
            <v-icon
              color="primary"
              class="info__icon info__icon--alert"
              size="50"
              alt="Service NSW"
            >
              mdi-alert-outline
            </v-icon>
          </v-avatar>
          <p class="info__text">
            <strong>NOTE:</strong> If you signed in with your Service NSW
            details, you will need to
            <a :href="snswAccountUrl" target="_blank"
              >go to your Service NSW Account</a
            >
            and sign out from there as well.
          </p>
        </div>
      </section>
    </v-container>
  </div>
</template>

<script>
import Banner from '@/components/Banner'
import { getSnswAccountUrl } from '@/utils/general'

export default {
  name: 'LogoutView',
  components: {
    Banner
  },
  data() {
    return {
      snswLink: process.env.VUE_APP_SNSW_MY_SERVICE_LINK
    }
  },
  computed: {
    isSignOutFromPNRForm() {
      return window.localStorage.getItem('pnrFlow') === 'true'
    },
    snswAccountUrl() {
      return getSnswAccountUrl()
    }
  }
}
</script>

<style lang="scss" scoped>
.signout {
  margin-bottom: 4rem;

  &__info {
    display: flex;
    align-items: center;

    @include mobile {
      flex-direction: column;
    }

    &:not(:last-child) {
      margin-bottom: 4rem;
    }
  }
}

h2 {
  font-size: 1.25rem;
}

.info {
  &__icon-wrapper {
    margin-right: 2rem;

    @include mobile {
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }

  &__icon--alert {
    margin-top: -0.5rem;
  }

  &__text {
    margin-bottom: 0;
    line-height: 1.7;

    &,
    a {
      color: $ads-dark;
    }
  }
}
</style>
