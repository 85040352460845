<template>
  <v-app
    :class="{
      mobile: $vuetify.breakpoint.smAndDown,
      tablet: $vuetify.breakpoint.mdOnly
    }"
  >
    <NavigationDrawer
      v-if="displayMobileNavDrawer"
      :color="drawerColor"
      class="pl-4 pr-4"
    >
      <template slot="preMenu">
        <v-list-item class="justify-end pr-0 pl-0">
          <v-list-item-icon class="mr-0">
            <v-btn icon @click.stop="$eventHub.$emit('toggle:navigation')">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-list-item-icon>
        </v-list-item>
        <Stepper @select="selectStep" />
      </template>
    </NavigationDrawer>

    <AppBar
      :color="colour"
      :title="appTitle"
      :show-full-screen="false"
      :show-nav-icon="displayMobileNavDrawer"
      class="header"
      :enable-home-link="false"
      data-testid="app-bar"
    >
      <template slot="appIcon">
        <v-img
          :class="getCls"
          width="32"
          height="35"
          src="@/assets/logo-colour.svg"
          alt="NSW Education logo"
        />
      </template>
      <template v-if="school && inTrainingMode" slot="dataSwitcher">
        <TrainingMenu
          :menu-items="menuItems"
          btn-text="Training - PI"
          subheader-text="Training Options"
          data-testid="training-menu"
        />
      </template>
      <template v-if="showResumeBtn && !isBlockEoiProceed" slot="profile">
        <AdsButton
          color="accent"
          button-text="Resume application"
          icon="restore"
          class="primary resumeApplicationBtn"
          data-testid="resume-application-btn"
          @click="resumeApplication"
        />
      </template>
      <template v-if="isFormRoute" slot="profile">
        <v-menu left>
          <template #activator="{ on, attrs }">
            <v-btn
              class="settingsBtn"
              v-bind="attrs"
              aria-label="Settings"
              v-on="on"
            >
              <v-icon class="material-icons-outlined">settings</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title class="listHeading"
                >Enrolment Options</v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="handleShowLogoutModal">
              <v-list-item-icon class="mr-4">
                <v-icon color="primary">mdi-logout-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="profile-title-font">
                Sign out
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </AppBar>

    <v-main data-testid="content">
      <v-container class="pa-0" fluid>
        <v-fade-transition mode="out-in">
          <IeBlocker v-if="isIe" :block="true" :public-app="true" />
          <router-view v-if="!isIe" ref="routerView" />
        </v-fade-transition>
      </v-container>
    </v-main>

    <AppFooter />
    <EnvironmentInfo />
    <LogoutConfirmationModal
      v-model="showLogoutModal"
      max-width="550px"
      sign-out
      @logout="logout"
    />
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import { getCognitoLogoutUrl } from '@/utils/general'
import {
  AppBar,
  NavigationDrawer,
  IeBlocker,
  AdsButton
} from '@nswdoe/doe-ui-core'
import AppFooter from '@/components/AppFooter'
import EnvironmentInfo from '@/components/EnvironmentInfo'
import Stepper from '@/components/Stepper'
import doeColors from '@/settings/doe-colors'
import LogoutConfirmationModal from '@/views/form/LogoutConfirmationModal'
import TrainingMenu from '@/components/TrainingMenu'
import { NODE_ENVIRONMENTS, ROUTE_NAMES, AUTH_SOURCE } from './constants'

export default {
  name: 'App',
  components: {
    AppBar,
    Stepper,
    NavigationDrawer,
    AppFooter,
    EnvironmentInfo,
    IeBlocker,
    LogoutConfirmationModal,
    TrainingMenu,
    AdsButton
  },
  data() {
    return {
      drawerColor: doeColors.userInterface.lightgrey10,
      isIe: false,
      showLogoutModal: false,
      actionsList: [
        {
          name: 'Sign out',
          color: '#041E42',
          size: 'large',
          outlined: false,
          btnText: false,
          action: 'logout'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['isBlockEoiProceed']),
    school() {
      return this.$store.getters.school
    },
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.width <= 300
    },
    resumeBreakpoint() {
      return this.$vuetify.breakpoint.width <= 525
    },
    cogBreakpoint() {
      return this.$vuetify.breakpoint.width <= 355
    },
    showResumeBtn() {
      return this.$route.name === 'expressionofinterest' && !this.isIe
    },
    displayMobileNavDrawer() {
      return this.$vuetify.breakpoint.smAndDown && this.isFormRoute
    },
    isFormRoute() {
      return (
        this.$route.matched.some((route) => route.name === ROUTE_NAMES.FORM) ||
        this.$route.name === 'pnrform' ||
        this.$route.name === 'pendingapplications'
      )
    },
    colour() {
      return this.inTrainingMode ? 'failure' : 'primary'
    },
    menuItems() {
      return [
        {
          text: 'Training - Local area enrolment (Parent Interface)',
          externalLink: `${process.env.VUE_APP_LOCAL_ENROLMENT_LINK}schoolCode=${this.school.schoolCode}&catchmentLevel=${this.school.catchmentLevel}`
        },
        {
          text: 'Training - Moving to high school (Parent Interface)',
          disabled: true
        },
        {
          text: 'Training - SI (School Interface)',
          externalLink: process.env.VUE_APP_SI_BASE_URL
        }
      ]
    },
    inTrainingMode() {
      return (
        process.env.VUE_APP_ENV_NAME &&
        process.env.VUE_APP_ENV_NAME !== NODE_ENVIRONMENTS.PROD &&
        process.env.VUE_APP_TRAINING_MODE === 'true'
      )
    },
    appTitle() {
      if (
        (!this.isFormRoute && this.resumeBreakpoint && this.showResumeBtn) ||
        this.mobileBreakpoint ||
        (this.isFormRoute && this.cogBreakpoint)
      ) {
        return ''
      }
      return 'Moving to high school'
    },
    getCls() {
      return this.isFormRoute ? 'ml-md-3 mr-md-2' : 'ml-3 mr-2'
    }
  },
  async created() {
    if (IeBlocker.isIe()) {
      this.isIe = true
    }
    if (!this.$store.getters.pilotSchools) {
      await this.$store.dispatch('getPilotSchools')
    }
    const schoolCode = window.sessionStorage.getItem('schoolCode')
    const validPrimarySchool =
      this.$store.getters.validY67TPrimarySchool(schoolCode)
    if (validPrimarySchool) {
      this.$store.commit('setSchool', validPrimarySchool)
    }
  },
  methods: {
    resumeApplication() {
      this.$store.commit('setIsResuming', true)
      // TODO: Determine whether we should detect auth state & bypass login
      this.$router.push({ name: 'login' })
    },
    selectStep(step) {
      this.$refs.routerView.selectStep(step)
    },
    async logout() {
      await this.$store.dispatch('saveApplication')
      this.showLogoutModal = false
      this.$store.dispatch('logout')
      this.$store.dispatch('resetApplication')
      if (sessionStorage.getItem('authSource') === AUTH_SOURCE.SNSW) {
        window.location.href = getCognitoLogoutUrl()
      } else {
        await this.$router.push({ name: ROUTE_NAMES.LOGOUT })
      }
    },
    handleShowLogoutModal() {
      this.showLogoutModal = true
    }
  }
}
</script>
<style lang="scss">
@import '@/scss/app.scss';

.header {
  @include desktop {
    padding: 0 50px;
  }
}

.appbar-title h1 {
  @include tablet {
    font-size: 16px !important;
    span > span {
      font-weight: 500 !important;
    }
  }
}

h1 {
  line-height: 1.2 !important;
}

.ie-blocker--container {
  position: relative !important;
  .ie-blocker--not-compatible-text {
    color: $ads-navy;
  }
  .v-icon {
    color: $ads-navy;
  }
}

.v-btn.settingsBtn.theme--dark.v-size--default {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background-color: $ads-light-blue !important;
  min-width: 40px;
  .v-btn__content .v-icon {
    color: $ads-navy;
  }
}

.v-toolbar__title {
  overflow: visible;
}

.listHeading {
  font-weight: bold;
  color: $ads-dark;
}
.v-btn.trainingMenu .v-btn__content {
  color: $ads-red;
  font-weight: bold;
}

.v-btn:not(.v-btn--round).v-size--default.resumeApplicationBtn {
  padding: 0 8px;
}
</style>
