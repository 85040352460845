import store from '@/store'

export default function form(to, from, next) {
  const { isAuthenticated, school } = store.getters
  const schoolCode = school?.schoolCode || sessionStorage.getItem('schoolCode')
  if (!isAuthenticated) {
    if (from?.name === 'logout' || schoolCode) {
      next({ name: 'home', query: { schoolCode } })
      sessionStorage.removeItem('authSource')
      return
    }
    next({ name: 'login' })
    return
  }

  store
    .dispatch('initReferenceData', {
      schoolCode: store.getters.application.currentPrimarySchool.schoolCode
    })
    .then(() => {
      next()
    })
    .catch((err) => {
      store.commit('setError', err)
      next({ name: 'error' })
    })
}
