<template>
  <Modal v-bind="$attrs" v-on="$listeners">
    <div id="LogoutConfirmationModal">
      <h1 class="heading">Sign out of online enrolment?</h1>
      <p>
        Are you sure you want to sign out of online enrolment?
        <span v-if="isPNRForm"
          >Any details you entered will be lost when you sign out.</span
        >
        <span v-else-if="isSignOut">
          Your progress will be saved, and you can resume your application at
          any time.
        </span>
        <span v-else>You can resume your application any time.</span>
      </p>

      <div class="actions">
        <v-spacer />
        <AdsButton
          v-if="!isSignOut"
          large
          secondary
          button-text="Cancel"
          class="mr-4"
          @click="$emit('input', false)"
        />
        <AdsButton
          large
          class="primary logout"
          button-text="Sign out"
          @click="$emit('logout')"
        />
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal'
import { AdsButton } from '@nswdoe/doe-ui-core'

export default {
  name: 'LogoutConfirmationModal',
  components: {
    Modal,
    AdsButton
  },
  props: {
    signOut: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isPNRForm() {
      return this.$route.name === 'pnrform'
    },
    isSignOut() {
      return this.signOut
    }
  }
}
</script>

<style lang="scss" scoped>
#LogoutConfirmationModal {
  font-weight: 500;
  margin: 0 -0.5rem -0.5rem -0.5rem;

  .heading {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1.25rem;
  }

  .actions {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    margin-top: 2rem;

    & button {
      font-weight: bold;

      padding: 0 2.5rem;
      height: 3rem;

      @include mobile {
        display: block;
        width: 100%;

        &:not(:last-child) {
          margin-top: 0.5rem;
        }
      }
      ::v-deep .v-btn__content {
        font-size: 16px;
      }
    }
  }

  h1 {
    color: $ads-navy;
    font-weight: bold;
    font-size: 1.25rem;
  }

  p:last-child {
    margin: 0;
  }
}
</style>
