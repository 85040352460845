/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import basePNRApp from '@/pnr-application-base'
import applicationApi from 'api-client/application'
import { converToISODate } from '@/utils/date'

const pnrFormModule = {
  state: {
    pnrApplication: JSON.parse(JSON.stringify(basePNRApp)),
    isPNRAdvice: false
  },
  getters: {
    pnrApplication: (state) => state.pnrApplication,
    isPNRAdvice: (state) => state.isPNRAdvice
  },
  mutations: {
    setPnrApplication(state, pnrApplication) {
      state.pnrApplication = pnrApplication
    },
    setIsPnrAdvice(state, isPNRAdvice) {
      state.isPNRAdvice = isPNRAdvice
    },
    setIsPnrSelective(state, isPNRSelective) {
      state.pnrApplication.consideringSelectiveSchool = isPNRSelective
    },
    setParentCarers(state, parentCarers) {
      state.pnrApplication.parentCarers = parentCarers
    },
    updatePNRStudent(state, updatedStudent) {
      const currStudent = state.pnrApplication.student || {}
      state.pnrApplication.student = { ...currStudent, ...updatedStudent }
    },
    updatePNRParentCarer(state, { index, parent }) {
      const currParent = state.pnrApplication.parentCarers?.[index] || {}
      this._vm.$set(state.pnrApplication.parentCarers, index, {
        ...currParent,
        ...parent
      })
    },
    updatePNRHighSchool(state, school) {
      state.pnrApplication.intendedHighSchool = school
    },
    setCurrentPrimarySchool(state, school) {
      if (school) {
        const { schoolCode, schoolName, catchmentLevel } = school
        state.pnrApplication.currentPrimarySchool = {
          schoolCode,
          schoolName,
          catchmentLevel
        }
      } else {
        state.pnrApplication.currentPrimarySchool = null
      }
    }
  },
  actions: {
    createAdvice({ commit, getters }) {
      const application = JSON.parse(JSON.stringify(basePNRApp))
      const { school } = getters
      if (school) {
        const { schoolCode, schoolName, catchmentLevel } = school
        application.currentPrimarySchool = {
          schoolCode,
          schoolName,
          catchmentLevel
        }
      }
      application.student = {}
      application.parentCarers = []
      application.intendedHighSchool = {}
      commit('setPnrApplication', application)
    },
    submitAdvice({ state }) {
      const { pnrApplication } = state
      pnrApplication.student.dateOfBirth = converToISODate(
        pnrApplication.student.dateOfBirth
      )
      return applicationApi.submitAdvice(state.pnrApplication)
    },
    resetAdvice({ commit }) {
      commit('setPnrApplication', JSON.parse(JSON.stringify(basePNRApp)))
      commit('setIsPnrAdvice', false)
    },
    setCurrentPrimarySchool({ commit }, school) {
      commit('setCurrentPrimarySchool', school)
    }
  }
}

export default pnrFormModule
