<template>
  <v-menu offset-y>
    <template #activator="{ on, attrs }">
      <v-btn color="white" class="trainingMenu" v-bind="attrs" v-on="on">
        {{ btnText }}
        <v-icon class="red--text text--darken-1 ml-1 mr-n2">
          mdi-menu-down
        </v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-subheader class="trainingOptions dropdown__subheader font-weight-bold">
        {{ subheaderText }}
      </v-subheader>
      <v-list-item
        v-for="(item, index) in menuItems"
        :key="index"
        class="list-item"
        :disabled="item.disabled"
        @click="itemClick(item.externalLink)"
      >
        <v-list-item-title class="list-item-title">{{
          item.text
        }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'TrainingMenu',
  props: {
    menuItems: {
      type: Array
    },
    subheaderText: {
      type: String,
      default: ''
    },
    btnText: {
      type: String,
      default: ''
    }
  },
  methods: {
    itemClick(url) {
      window.open(url, '_self')
    }
  }
}
</script>

<style lang="scss" scoped>
.v-subheader.trainingOptions {
  font-size: 1rem;
  color: $ads-dark;
}
</style>
