<template>
  <Modal v-bind="$attrs" v-on="$listeners">
    <div id="ModalResidentialAddress">
      <h1>Proof of Student's Residential Address</h1>

      <p>
        To confirm your enrolment after submitting your application, you may be
        asked for a <strong>100-point residential address check</strong>. Below
        is a guide to the documents schools can accept and how many points each
        are worth.
      </p>

      <table>
        <thead>
          <tr>
            <th>Document showing the full name of the child’s parent</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p>
                Only one of (i.e. no additional points for additional documents)
              </p>
              <ol>
                <li>Council rates notice</li>
                <li>
                  Lease agreement through a registered real estate agent for a
                  period of at least 6 months or rental board bond receipt
                </li>
                <li>
                  Exchanged contract of sale with settlement to occur within the
                  applicable school year
                </li>
              </ol>
              <p class="points">40 POINTS</p>
            </td>
          </tr>

          <tr>
            <td>
              <p>Any of the following</p>
              <ol>
                <li>
                  Private rental agreement for a period of at least 6 months
                </li>
                <li>Centrelink payment statement showing home address</li>
                <li>Electoral roll statement</li>
              </ol>
              <p class="points">20 POINTS</p>
            </td>
          </tr>

          <tr>
            <td>
              <p>Any of the following documents</p>
              <ol>
                <li>Electricity or gas bill showing the service address*</li>
                <li>Water bill showing the service address*</li>
                <li>Telephone or internet bill showing the service address*</li>
                <li>
                  Drivers licence or government issued ID showing home address*
                </li>
                <li>
                  Home building or home contents insurance showing the service
                  address
                </li>
                <li>
                  Motor vehicle registration or compulsory third party insurance
                  policy showing home address
                </li>
                <li>
                  Statutory declaration stating the child’s residential address,
                  how long they have lived there, and any supporting information
                  or documentation of this.
                </li>
              </ol>
              <p class="points">15 POINTS</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p class="footnote">* up to three months old</p>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal'

export default {
  name: 'ResidentialAddressModal',
  components: {
    Modal
  }
}
</script>

<style lang="scss" scoped>
#ModalResidentialAddress {
  font-weight: 500;

  .points {
    margin-top: 0.75rem;
    font-weight: bold;
  }

  h1 {
    margin-bottom: 1.25rem;
    color: $ads-navy;
    font-weight: bold;
    font-size: 1.25rem;
  }

  table {
    counter-reset: row;
    margin-top: 1.25rem;
    font-size: 1rem;
    width: 100%;
    border-collapse: collapse;

    p {
      margin: 0;
    }
  }

  th {
    background: #1d428a;
    border: 1px solid #1d428a;
    color: white;
    font-weight: bold;
    padding: 0.5rem 1rem;
    text-align: left;
  }

  td {
    border: 1px solid #a0a5ae;
    padding: 1rem;
    counter-increment: row;
  }

  ol {
    counter-reset: item;
    padding-left: 0;
  }
  li {
    display: block;
  }
  li:before {
    content: counter(row) '.' counter(item) ' ';
    counter-increment: item;
  }
}
</style>
