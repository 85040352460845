<template>
  <v-text-field
    :ref="inputRef"
    :class="{ 'readonly-text': readonly }"
    :readonly="readonly"
    v-bind="attrs"
    :label="label"
    :rules="internalRules"
    :aria-describedby="messageBoxId"
    :aria-invalid="!isValid"
    v-on="$listeners"
    persistent-placeholder
  >
    <template #message="data">
      <div
        :id="messageBoxId"
        class="v-messages__message"
        v-html="data.message"
      />
    </template>
  </v-text-field>
</template>

<script>
import requiredMixin from '@/mixins/required'
import accessibilityMixin from '@/mixins/fieldAccessibility'

export default {
  name: 'TextField',
  mixins: [requiredMixin, accessibilityMixin('textField')],
  inheritAttrs: false,
  props: {
    readonly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    attrs() {
      const defaultProps = {
        'persistent-hint': true,
        outlined: true
      }
      return { ...defaultProps, ...this.$attrs }
    }
  }
}
</script>

<style lang="scss" scoped></style>
