<template>
  <!-- eslint-disable max-len -->
  <div class="white-card school-card">
    <slot name="logo">
      <img
        v-if="imageSrc"
        class="school-card__logo"
        :src="imageSrc"
        :alt="imageAlt"
      />
    </slot>
    <div class="school-card__text-content">
      <slot name="schoolName">
        <v-radio
          v-if="selectable"
          class="school-card__radio"
          v-bind="internalRadioAttrs"
        />
        <h4 v-else-if="schoolName" class="school-card__heading">
          {{ schoolName }}
        </h4>
      </slot>
      <ol
        class="school-card__list"
        :class="{ indent: schoolName && selectable }"
      >
        <li
          v-if="addressText"
          class="school-card__list-item school-card__list-item--address"
        >
          <slot name="addressIcon">
            <v-icon class="list-item__icon list-item__icon--address">
              mdi-map-marker-outline
            </v-icon>
          </slot>
          <slot name="address">
            <a
              v-if="addressUrl"
              class="list-item__text list-item__text--address"
              :href="addressUrl"
              target="_blank"
              rel="noopener"
              >{{ addressText }}
              <span class="sr-only">this link will open in a new tab</span>
            </a>
            <span v-else class="list-item__text list-item__text--address"
              >{{ addressText }}
            </span>
          </slot>
        </li>
        <li
          v-if="phoneNumber"
          class="school-card__list-item school-card__list-item--phone"
        >
          <slot name="phoneIcon">
            <v-icon class="list-item__icon list-item__icon--phone">
              mdi-phone-outline
            </v-icon>
          </slot>
          <slot name="phone">
            <span class="list-item__text list-item__text--phone">{{
              phoneNumber
            }}</span>
          </slot>
        </li>
        <li
          v-if="websiteText || websiteUrl"
          class="school-card__list-item school-card__list-item--website"
        >
          <slot name="websiteIcon">
            <v-icon class="list-item__icon list-item__icon--website">
              mdi-web
            </v-icon>
          </slot>
          <slot name="website">
            <a
              v-if="websiteUrl"
              class="list-item__text list-item__text--website"
              :href="websiteUrl"
              target="_blank"
              rel="noopener"
            >
              {{ websiteText || websiteUrl }}
              <span class="sr-only">this link will open in a new tab</span>
            </a>
            <span v-else class="list-item__text list-item__text--website">
              {{ websiteText }}
            </span>
          </slot>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SchoolCard',
  props: {
    schoolName: {
      type: String
    },
    imageSrc: {
      type: String
    },
    imageAlt: {
      type: String
    },
    addressText: {
      type: String
    },
    addressUrl: {
      type: String
    },
    phoneNumber: {
      type: String
    },
    websiteText: {
      type: String
    },
    websiteUrl: {
      type: String
    },
    selectable: {
      type: Boolean
    },
    radioAttrs: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    internalRadioAttrs() {
      const defaultProps = {
        label: this.schoolName,
        value: this.key
      }
      return { ...defaultProps, ...this.radioAttrs }
    }
  }
}
</script>

<style lang="scss" scoped>
.school-card {
  display: flex;
  align-items: center;
  padding: 3rem;

  @include mobile {
    flex-direction: column;
  }

  &__logo {
    height: 7rem;
    padding-right: 3rem;
    max-width: 100%;
    max-height: 100%;

    @include mobile {
      padding-right: 0;
      margin-bottom: 1.5rem;
    }
  }

  &__heading,
  &__radio.v-radio ::v-deep label.v-label {
    color: $ads-navy;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.5;
  }

  &__heading,
  &__radio {
    margin-bottom: 0.5rem;
  }

  &__list {
    list-style: none;
    padding: 0;

    &.indent {
      margin-left: 2.5rem;
    }
  }

  &__list-item {
    font-size: 1rem;
    line-height: 1.5;
    margin: 0.4rem 0;
    display: flex;
    align-items: center;
  }
}

::v-deep label.v-label {
  margin-left: 0.5rem;
}

.list-item {
  &__text {
    padding-left: 1rem;
    font-weight: 500;
  }

  &__text,
  &__icon {
    color: $ads-navy;
  }

  &__icon {
    margin-left: -3px;
  }
}
</style>
