<!-- eslint-disable max-len -->
<!-- eslint-disable -->
<template>
  <div class="pa-6 white-card external-login">
    <v-row>
      <v-col cols="12" md="3" class="text-center">
        <img
          v-if="imageSrc"
          :alt="imageAlt"
          :src="imageSrc"
          class="external-login__image"
        />
      </v-col>
      <v-col cols="12" md="9" class="pb-0">
        <v-row>
          <v-col class="pt-0">
            <h3 class="external-login__heading mb-2">
              <slot name="heading">
                {{ heading }}
              </slot>
            </h3>
            <div class="external-login__text">
              <slot name="text" />
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="isPnrFlow">
      <v-spacer />
      <v-col cols="12" md="9" class="pb-0">
        <v-row>
          <v-checkbox
            v-model="acceptTnc"
            class="login-card__tnc"
            data-testid="email-privacy-check"
          >
            <template v-slot:label>
              I have read and agree to the
              <a
                tabindex="0"
                ref="privacy"
                @click="$emit('showPrivacy')"
                @keyup.enter="$emit('showPrivacy')"
                >privacy agreement</a
              >
            </template>
          </v-checkbox>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer />
      <v-col cols="12" md="7" sm="9" class="external-login__additional-content">
        <slot name="additionalContent" />
      </v-col>
      <v-col cols="12" md="2" sm="3" class="external-login__button-wrapper">
        <AdsButton
          x-large
          class="external-login__button primary"
          button-text="Continue"
          :disabled="disableContinue"
          @click="$emit('beginAuth')"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { AdsButton } from '@nswdoe/doe-ui-core'

export default {
  name: 'OesExternalLogin',
  components: {
    AdsButton
  },
  props: {
    imageSrc: {
      type: String
    },
    isPnrFlow: {
      type: Boolean,
      default: false
    },
    imageAlt: {
      type: String
    },
    heading: {
      type: String
    }
  },
  data() {
    return {
      acceptTnc: false
    }
  },
  computed: {
    disableContinue() {
      if (this.isPnrFlow && !this.acceptTnc) {
        return true
      }

      return false
    }
  }
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: underline;
}

a:focus {
  border: 2px solid $ads-navy !important;
  outline-color: $ads-navy !important;
}

.external-login__heading,
.external-login__text {
  color: $color-text-body;
}

.external-login__button {
  width: 100%;
}

.external-login__button-wrapper {
  align-self: flex-end;
}

::v-deep .login-card__tnc.v-input--checkbox .v-label {
  display: inline-block;
  color: $color-text-body;
  font-weight: 500;
}
</style>
