import axios from 'axios'
import store from '@/store'

// Environment variables
const API_REFDATA = process.env.VUE_APP_API_REFDATA

const getReferenceData = () => {
  const url = `${API_REFDATA}/pi-reference-data.json`

  return axios
    .get(url)
    .then((res) => res.data)
    .catch(() => {
      // set error in store & throw exceptions so that it can be propagated to view for handling.
      const error = new Error(
        'Failed to load reference data (pi-reference-data.json).'
      )
      store.commit('setError', error)
      throw error
    })
}

const getPredictiveSchools = () => {
  const url = `${API_REFDATA}/all-schools.json`

  return axios
    .get(url)
    .then((res) => res.data)
    .catch(() => {
      // set error in store & throw exceptions so that it can be propagated to view for handling.
      const error = new Error(
        'Failed to load reference data (all-schools.json).'
      )
      store.commit('setError', error)
      throw error
    })
}

const getHighSchools = () => {
  const url = `${API_REFDATA}/high_schools.json`

  return axios
    .get(url)
    .then((res) => res.data)
    .catch(() => {
      // set error in store & throw exceptions so that it can be propagated to view for handling.
      const error = new Error(
        'Failed to load reference data (high_schools.json).'
      )
      store.commit('setError', error)
      throw error
    })
}

const getOESProperties = () => {
  const url = `${API_REFDATA}/oes-properties.json`

  return axios
    .get(url)
    .then((res) => res.data)
    .catch(() => {
      // set error in store & throw exceptions so that it can be propagated to view for handling.
      const error = new Error(
        'Failed to load reference data (oes-properties.json).'
      )
      store.commit('setError', error)
      throw error
    })
}

const getCustomisationDefinitions = () => {
  const url = `${API_REFDATA}/global-web-content-ref.json`

  return axios
    .get(url)
    .then((res) => res.data)
    .catch(() => {
      // set error in store & throw exceptions so that it can be propagated to view for handling.
      const error = new Error(
        'Failed to load global web content data (global-web-content-ref.json)'
      )
      store.commit('setError', error)
      throw error
    })
}

export default {
  getReferenceData,
  getPredictiveSchools,
  getOESProperties,
  getHighSchools,
  getCustomisationDefinitions
}
