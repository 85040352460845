import Vue from 'vue'
import VueRouter from 'vue-router'

import LoginOTP from '@/views/LoginOTP'
import LoginView from '@/views/LoginView'
import NotFound from '@/views/NotFound'
import WelcomeBack from '@/views/WelcomeBack'
import Confirmation from '@/views/Confirmation'
import Timeout from '@/views/Timeout'
import LogoutView from '@/views/LogoutView'
import PendingApplications from '@/views/PendingApplications'
import PnrForm from '@/views/pnrForm'
import EoiForm from '@/views/eoiForm'
import { ROUTE_NAMES } from '@/constants'
import confirmationGuard from './confirmationGuards'
import formGuard from './formGuards'
import homeGuard from './guards'
import pnrFormGuards from './pnrFormGuards'

const Form = () => import(/* webpackChunkName: "form" */ '@/views/AppForm')
const StudentView = () =>
  import(/* webpackChunkName: "form" */ '@/views/form/Student')
const ParentCarerView = () =>
  import(/* webpackChunkName: "form" */ '@/views/form/ParentCarer')
const HighSchoolView = () =>
  import(/* webpackChunkName: "form" */ '@/views/form/HighSchool')
const ConsiderationsView = () =>
  import(/* webpackChunkName: "form" */ '@/views/form/Considerations')
const ReviewView = () =>
  import(/* webpackChunkName: "form" */ '@/views/form/Review')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home'),
    beforeEnter: homeGuard
  },
  {
    path: '/noschool',
    name: 'noSchool',
    component: () =>
      import(/* webpackChunkName: "noschool" */ '@/views/NoSchool'),
    meta: {
      title: 'Non NSW address'
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () =>
      import(/* webpackChunkName: "privacy" */ '@/views/Privacy'),
    meta: {
      title: 'Privacy'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    props: true,
    meta: {
      title: 'Login'
    }
  },
  {
    path: '/login/otp',
    name: 'otp',
    component: LoginOTP,
    props: true,
    meta: {
      title: 'Verification'
    }
  },
  {
    path: '/login/return',
    name: 'return',
    component: WelcomeBack,
    meta: {
      title: 'Resume Application'
    }
  },
  {
    path: '/login/timeout',
    name: 'timeout',
    component: Timeout,
    meta: {
      title: 'Session Expired'
    }
  },
  {
    path: '/form',
    name: ROUTE_NAMES.FORM,
    component: Form,
    beforeEnter: formGuard,
    children: [
      {
        path: 'student',
        name: 'Student',
        component: StudentView,
        meta: {
          title: 'Student details'
        }
      },
      {
        path: 'parentcarer',
        name: 'ParentCarer',
        component: ParentCarerView,
        meta: {
          title: 'Parent/carer details'
        }
      },
      {
        path: 'highschool',
        name: 'HighSchool',
        component: HighSchoolView,
        meta: {
          title: 'High school options'
        }
      },
      {
        path: 'considerations/:index',
        props: true,
        name: 'Considerations',
        component: ConsiderationsView,
        meta: {
          title: 'Considerations'
        }
      },
      {
        path: 'review',
        name: 'Review',
        component: ReviewView,
        meta: {
          title: 'Review & submit'
        }
      }
    ]
  },
  {
    path: '/confirmation',
    name: ROUTE_NAMES.CONFIRMATION,
    component: Confirmation,
    beforeEnter: confirmationGuard,
    meta: {
      title: 'Confirmation'
    }
  },
  {
    path: '/error',
    name: ROUTE_NAMES.ERROR,
    component: () => import(/* webpackChunkName: "error" */ '@/views/Error'),
    meta: {
      title: 'Error'
    }
  },
  {
    path: '/noapplication',
    name: ROUTE_NAMES.NOAPPLICATION,
    component: () => import('@/views/ResumeNoAppView'),
    meta: {
      title: 'No application'
    }
  },
  {
    path: '*',
    component: NotFound
  },
  {
    path: '/logout',
    name: ROUTE_NAMES.LOGOUT,
    component: LogoutView,
    meta: {
      title: 'Logout'
    }
  },
  {
    path: '/pendingapplications',
    name: 'pendingapplications',
    component: PendingApplications,
    beforeEnter: formGuard,
    meta: {
      title: 'Pending applications'
    }
  },
  {
    path: '/no-placement',
    name: 'noplacement',
    component: LoginView,
    beforeEnter: homeGuard,
    props: true,
    meta: {
      title: 'Placement not required'
    }
  },
  {
    path: '/pnrform',
    name: 'pnrform',
    component: PnrForm,
    beforeEnter: pnrFormGuards,
    meta: {
      title: 'Details'
    }
  },
  {
    path: '/expressionofinterest',
    name: 'expressionofinterest',
    component: EoiForm,
    beforeEnter: homeGuard,
    props: true,
    meta: {
      title: 'Expression of interest'
    }
  }
]

const isEhubApp = window.location.origin.indexOf('ehub') !== -1
const isEhubLocalDev = process.env.VUE_APP_EHUB_LOCAL_DEV === 'true'

const router = new VueRouter({
  mode: 'history',
  base: isEhubApp || isEhubLocalDev ? '/year7/' : '',
  routes
})

router.beforeEach((to, from, next) => {
  const title = to.meta?.title
  document.title = title
    ? `${title} | Moving to high school`
    : 'Moving to high school'
  next()
})

router.afterEach(() => {
  window.scroll(0, 0)
})

export default router
