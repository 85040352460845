// User In-activity timeout functions. Description of what happens follows:
// 1. When the SPA is loaded by a client, we register a set of events to listen for that
// determine whether a user has interacted with the application or not.
// 2. When the user authenticates by providing a valid OTP we start tracking the session
// inactivity by setting a timeout period of 60 min using the Window setTimeout() method.
// 3. Each time the user triggers one of the registered events, we reset the remaining time back
// to 60 min by firstly using the Window clearTimeout() method then re-setting the timeout.
// 4. We throttle how often the timer is reset so as not to overwhelm the client. i.e. track
// necessary events, but only reset the timeout every X seconds (currently set to 1sec).
// If we were to enable mousemove events, we'd probably need to increase the throttle timeout.
let throttlerTimeoutID
let activityTimeoutID

function activateActivityTracker(events, throttler) {
  events.forEach((event) => window.addEventListener(event, throttler))
  throttler()
}

function deactivateActivityTracker(events, throttler) {
  events.forEach((event) => window.removeEventListener(event, throttler))
  clearTimeout(throttlerTimeoutID)
  clearTimeout(activityTimeoutID)
}

function resetUserActivityTimeout(
  activityTimeoutDuration,
  inactiveUserCallback
) {
  // reset the user activity timeout due to one of the listener events being triggered.
  clearTimeout(activityTimeoutID)
  activityTimeoutID = setTimeout(() => {
    inactiveUserCallback()
  }, activityTimeoutDuration)
}

function createActivityThrottler(
  throttlerTimeoutDuration,
  activityTimeoutDuration,
  inactiveUserCallback
) {
  return () => {
    if (!throttlerTimeoutID) {
      throttlerTimeoutID = setTimeout(() => {
        resetUserActivityTimeout(
          activityTimeoutDuration - throttlerTimeoutDuration,
          inactiveUserCallback
        )
        clearTimeout(throttlerTimeoutID)
        throttlerTimeoutID = null
      }, throttlerTimeoutDuration)
    }
  }
}

function validateParams(
  events,
  inactivityTimeout,
  throttlerTimeout,
  onInactiveUser
) {
  const isString = (item) => typeof item === 'string'
  const isNumber = (item) => typeof item === 'number'
  const isFunction = (item) => typeof item === 'function'

  if (!Array.isArray(events) || !events.every(isString)) {
    return 'events parameter must be an array of strings. e.g. ["keydown", "mousedown"]'
  }
  if (!isNumber(inactivityTimeout) || !isNumber(throttlerTimeout)) {
    return 'timeout variables must be numbers.'
  }
  if (!isFunction(onInactiveUser)) {
    return 'onInactiveUser must be a function.'
  }
  return null
}
export default {
  install(
    Vue,
    {
      events = ['keydown'],
      inactivityTimeout = 3600000,
      throttlerTimeout = 1000,
      onInactiveUser
    }
  ) {
    const throttler = createActivityThrottler(
      throttlerTimeout,
      inactivityTimeout,
      onInactiveUser
    )

    const error = validateParams(
      events,
      inactivityTimeout,
      throttlerTimeout,
      onInactiveUser
    )

    if (error) {
      throw new Error(error)
    }

    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$activityMonitor = {
      activate() {
        activateActivityTracker(events, throttler)
      },
      deactivate() {
        deactivateActivityTracker(events, throttler)
      }
    }
  }
}
