<template>
  <!-- eslint-disable max-len -->
  <div>
    <Banner>
      <template #heading>
        <h1 class="banner__heading">
          An application already exists
          <br />
          for this email address
        </h1>
      </template>
    </Banner>
    <v-container fluid class="centeredContent py-16">
      <OutageNotification />
      <v-card class="pa-6" data-testid="resume-application-section">
        <v-row>
          <v-col cols="3" class="text-center">
            <v-avatar color="rgb(199, 220, 240)" size="60">
              <v-icon class="width35">restore</v-icon>
            </v-avatar>
          </v-col>
          <v-col cols="9" class="px-0">
            <h2>Resume expression of interest in progress</h2>
            <p>
              Select “Resume” to continue the expression of interest you have in
              progress
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" class="text-center">
            <v-icon class="material-icons-outlined width40">person</v-icon>
          </v-col>
          <v-col cols="9">
            <v-row>
              <v-col cols="12" md="8" sm="12" class="pa-0">
                <h3>{{ studentName }}</h3>
                <p><strong>Currently attending:</strong> {{ schoolName }}</p>
              </v-col>
              <v-col cols="12" md="4" sm="12" class="pa-0">
                <AdsButton
                  class="adsButton"
                  button-text="Resume"
                  data-testid="resume-application-btn"
                  x-large
                  @click="resumeApplication"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
      <div class="text-center login-divider"><strong>OR</strong></div>
      <v-card class="pa-6" data-testid="create-new-application-section">
        <v-row>
          <v-col cols="3" class="text-center">
            <v-avatar color="rgb(199, 220, 240)" size="60">
              <v-icon class="width35">open_in_new</v-icon>
            </v-avatar>
          </v-col>
          <v-col cols="9">
            <v-row>
              <v-col cols="12" md="8" sm="12" class="pa-0">
                <h2>Start a new expression of interest</h2>
                <p>
                  <strong>Remove</strong> the expression of interest you have in
                  progress and start a new one
                </p>
              </v-col>
              <v-col cols="12" md="4" sm="12" class="px-0">
                <AdsButton
                  class="adsButton"
                  button-text="New"
                  data-testid="create-application-btn"
                  secondary
                  x-large
                  @click="proceedNewApplication"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
      <InProgressApplicationModal
        v-model="showModal"
        max-width="680px"
        :school-name="schoolName"
        :student-name="studentName"
        @cancel="showModal = false"
        @proceed="createNewApplication"
      />
    </v-container>
    <LogoutConfirmationModal
      v-model="showLeaveModal"
      max-width="550px"
      @logout="handleLogout"
    />
  </div>
</template>

<script>
import Banner from '@/components/Banner'
import { ROUTE_NAMES } from '@/constants'
import InProgressApplicationModal from '@/views/form/InProgressApplicationModal'
import LogoutConfirmationModal from '@/views/form/LogoutConfirmationModal'
import OutageNotification from '@/views/OutageNotification'
import { AdsButton } from '@nswdoe/doe-ui-core'

// Navigation routes that should NOT be stopped by the 'leaving form' modal when navigating to.
const VALID_NAVIGATION_TARGETS = [
  ROUTE_NAMES.LOGOUT,
  ROUTE_NAMES.FORM,
  ROUTE_NAMES.ERROR,
  ROUTE_NAMES.TIMEOUT
]

export default {
  name: 'PendingApplications',
  components: {
    Banner,
    AdsButton,
    InProgressApplicationModal,
    LogoutConfirmationModal,
    OutageNotification
  },
  data() {
    return {
      showModal: false,
      showLeaveModal: false
    }
  },
  computed: {
    application() {
      return this.$store.getters.application
    },
    studentName() {
      const studentDetails = this.application.student
      if (
        studentDetails &&
        (studentDetails.firstName ||
          studentDetails.middleName ||
          studentDetails.familyName)
      ) {
        const studentFirstName = studentDetails.firstName
          ? studentDetails.firstName.trim()
          : ''
        const studentMiddleName = studentDetails.middleName
          ? studentDetails.middleName.trim()
          : ''
        const studentFamilyName = studentDetails.familyName
          ? studentDetails.familyName.trim()
          : ''
        return `${studentFirstName} ${studentMiddleName} ${studentFamilyName}`.trim()
      }
      return 'Your Child'
    },
    schoolName() {
      return this.application.currentPrimarySchool?.schoolName
    }
  },
  async beforeRouteLeave(to, from, next) {
    if (VALID_NAVIGATION_TARGETS.includes(to.name)) {
      next()
    } else {
      this.showLeaveModal = true
      next(false)
    }
  },
  beforeMount() {
    this.$store.commit('setIsResuming', true) // Ensure going 'back' after entering form is considered resuming
    this.$activityMonitor.activate()
  },
  beforeDestroy() {
    this.$activityMonitor.deactivate()
  },
  methods: {
    resumeApplication() {
      // set school from the application
      const school = this.application.currentPrimarySchool
      sessionStorage.setItem('schoolCode', school.schoolCode)
      this.$store.commit('setSchool', school)
      this.$router.push({ name: 'form' })
    },
    proceedNewApplication() {
      this.showModal = true
    },
    async createNewApplication() {
      const school = this.application.currentPrimarySchool
      sessionStorage.setItem('schoolCode', school.schoolCode)
      this.$store
        .dispatch('createApplication')
        .then(this.$router.push({ name: 'form' }))
    },
    async handleLogout() {
      this.showLeaveModal = false
      this.$store.dispatch('logout')
      this.$router.push({ name: ROUTE_NAMES.LOGOUT })
    }
  }
}
</script>

<style lang="scss" scoped>
.adsButton {
  width: 90%;
}
.login-divider {
  font-size: 1.5rem;
  text-align: center;
  margin: 2rem 0;
}
.width35 {
  font-size: 2.2rem;
}
.width40 {
  font-size: 2.5rem;
}
</style>
