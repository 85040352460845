<template>
  <v-select
    :ref="inputRef"
    :value="value"
    :placeholder="value ? null : placeholder"
    v-bind="attrs"
    :label="label"
    :aria-describedby="messageBoxId"
    :aria-invalid="!isValid"
    :rules="internalRules"
    v-on="$listeners"
    @keydown.native="ie11BackspaceFix"
    persistent-placeholder
  >
    <template #message="data">
      <div
        :id="messageBoxId"
        class="v-messages__message"
        v-html="data.message"
      />
    </template>
  </v-select>
</template>

<script>
import requiredMixin from '@/mixins/required'
import accessibilityMixin from '@/mixins/fieldAccessibility'

export default {
  name: 'SelectMenu',
  mixins: [requiredMixin, accessibilityMixin('select')],
  inheritAttrs: false,
  props: {
    value: {
      type: String
    },
    placeholder: {
      type: String,
      default: 'Select…'
    }
  },
  computed: {
    attrs() {
      const defaultProps = {
        'persistent-hint': true,
        outlined: true,
        placeholder: 'Select…'
      }
      return { ...defaultProps, ...this.$attrs }
    }
  },
  methods: {
    /*
    Placeholder fix details:
    https://stackoverflow.com/questions/56193666/why-when-you-click-outside-the-v-select-the-v-model-resets-to-null-vuetify

    Credit to @John W for backspace fix!
    v-selects don't work correctly with IE11. They lose the selected value (a bug with placeholders)
    and they navigate away from the page if Backspace is hit.

    The placeholder issue is fixed by not using placeholders. However empty placeholders have to be
    set to achieve the label size & positioning requested by UX. So instead we now only set an empty
    placeholder when no dropdown list value is selected. This seems to fix the issue.

    The backspace issue is fixed simply by discarding backspace events.
    */
    ie11BackspaceFix(e) {
      if (e.key === 'Backspace') {
        e.preventDefault()
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
