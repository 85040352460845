<!-- Content with an image on the left, heading and text on the right. -->
<template>
  <v-row
    class="d-flex align-start pa-6 content-box"
    data-testid="content-box"
    :style="{ 'flex-wrap': $vuetify.breakpoint.xsOnly ? 'wrap' : 'nowrap' }"
    :class="{ 'justify-center': $vuetify.breakpoint.xsOnly }"
  >
    <slot name="img">
      <div :class="imageClass">
        <div
          class="content-img"
          data-testid="content-img"
          :class="{ rightSpacing: $vuetify.breakpoint.smAndUp }"
        >
          <img
            v-if="imgFileName"
            :class="imageClass"
            :alt="imgAlt || title"
            :src="require(`@/assets/${imgFileName}`)"
          />
        </div>
      </div>
    </slot>

    <slot name="content">
      <div :class="{ 'text-center': $vuetify.breakpoint.xsOnly }">
        <slot name="title">
          <h2 class="pb-2">{{ title }}</h2>
        </slot>

        <slot name="copyContent">
          <p>{{ content }}</p>
        </slot>
      </div>
    </slot>
  </v-row>
</template>

<script>
export default {
  name: 'ContentBox',
  props: {
    title: {
      type: String
    },
    content: {
      type: String
    },
    imgFileName: {
      type: String
    },
    imgAlt: {
      type: String
    },
    imageClass: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.content-box {
  margin: 0px 15px;
}
.mobile .content-img {
  margin-bottom: 12px;
}
.content-img img {
  width: 70px;
  height: 70px;
}
.content-img img.largeImage {
  width: 88px;
  height: 88px;
}
.content-img.rightSpacing {
  margin-right: 40px;
}
.largeImage .content-img.rightSpacing {
  margin-right: 25px;
}
</style>
