<template>
  <!-- eslint-disable max-len -->
  <v-container fluid class="content">
    <Banner>
      <!-- eslint-disable-next-line -->
      <h1>Whoops!<br />That page cannot be found...</h1>
    </Banner>
    <v-container fluid class="centeredContent">
      <v-container class="form-container">
        <div class="error-container elevation-2 pa-5 pb-9 text-center">
          <div class="icon-container">
            <img
              class="error-icon"
              alt="timeout"
              width="100px"
              :src="require(`@/assets/ads-404-icon.svg`)"
            />
          </div>
          <div>
            <h1 class="pb-sm-1 pb-1">404 error</h1>
            <h2 class="pb-sm-8 pb-4">
              The page you are looking for does not exist.
            </h2>
            <p class="mb-7">
              If you continue to experience this error please
              <a
                href="https://enrolmentassist.freshdesk.com/support/tickets/new"
                rel="external noopener"
                target="_blank"
                >Contact our Support Team
                <span class="sr-only">this link will open in a new tab</span></a
              >
            </p>
          </div>
        </div>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import Banner from '@/components/Banner'

export default {
  name: 'NotFound',
  components: {
    Banner
  }
}
</script>

<style lang="scss" scoped>
.error-container {
  background-color: $ads-white;
  border-radius: 4px;
  text-align: center;

  h1 {
    color: $ads-navy !important;
  }
  h2 {
    font-size: 24px;
    color: $ads-navy !important;
  }
  .icon-container {
    width: 161px;
    height: 161px;
    text-align: center;
    vertical-align: middle;
    margin: 1.5rem auto;

    .error-icon {
      padding-top: 30px;
      margin: auto;
    }
  }
}
.form-container {
  padding-top: 40px;
}
</style>
