import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['steps']),
    currentStepObject() {
      return this.steps.find(({ route }) => this.$route.path === route)
    },
    currentStepNum() {
      return this.currentStepObject?.step
    }
  },
  methods: {
    goToStepNo(stepNo, replace = false) {
      const step = this.steps.find(({ step }) => stepNo === step)
      if (step?.route) {
        if (replace) {
          this.$router.replace(step.route)
        } else {
          this.$router.push(step.route)
        }
      }
    },
    goToStepByKey(stepKey) {
      const step = this.steps.find(({ key }) => stepKey === key)
      if (step?.route) {
        this.$router.push(step.route)
      }
    }
  }
}
