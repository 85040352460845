<template>
  <!-- eslint-disable max-len -->
  <div class="content">
    <Banner
      :image-src="require('@/assets/icon-school-alt.svg')"
      heading="Your session has timed out"
    />
    <v-container fluid class="mt-12 centeredContent">
      <div class="error-container elevation-2 pa-5 pb-9 text-center">
        <div class="icon-container">
          <img
            class="error-icon"
            alt="timeout"
            width="100px"
            :src="require(`@/assets/icon-timeout.png`)"
          />
        </div>
        <div>
          <h2 class="pb-sm-8 pb-4">Your session has timed out</h2>
          <div v-if="isPNRAdvice" class="pnr-msg">
            To confirm you will not be requiring a high school placement, go to
            the school website you used to start, choose
            <strong>Moving to high school</strong>, then select the link to
            advise your primary school a high school placement is not required
          </div>
          <div v-else-if="isPendingapplications">
            You can
            <v-btn
              text
              class="btn-link"
              data-testid="resume-button"
              @click="resume"
            >
              resume an existing application
            </v-btn>
            or
            <v-btn
              text
              class="btn-link"
              data-testid="start-new-application-button"
              @click="startNewApplication"
            >
              start a new application
            </v-btn>
          </div>
          <div v-else>
            <v-btn
              text
              class="btn-link"
              data-testid="resume-button"
              @click="resume"
            >
              Resume Application
            </v-btn>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import Banner from '@/components/Banner'

export default {
  name: 'AppTimeout',
  components: {
    Banner
  },
  data() {
    return {
      previousRoute: null
    }
  },
  beforeRouteEnter(to, from, next) {
    // eslint-disable-next-line no-param-reassign
    next((vm) => {
      vm.previousRoute = from
    })
  },
  computed: {
    email() {
      return this.$store.getters.email
    },
    isPNRAdvice() {
      return this.$store.getters.isPNRAdvice
    },
    isPendingapplications() {
      return this.previousRoute?.name === 'pendingapplications'
    },
    school() {
      return this.$store.getters.school
    }
  },
  methods: {
    resume() {
      this.$store.commit('setIsResuming', true)
      this.$router.push({
        name: 'login',
        params: { resumeSessionExpired: !!this.email, userEmail: this.email }
      })
    },
    startNewApplication() {
      this.$router.push({
        name: 'home',
        query: this.school?.schoolCode
          ? { schoolCode: this.school.schoolCode }
          : ''
      })
    }
  }
}
</script>

<style lang="scss" scoped>
h2 {
  font-size: 24px;
  color: $ads-navy !important;
}
.error-container {
  background-color: $ads-white;
  border-radius: 4px;
  text-align: center;

  .icon-container {
    background-color: $ads-light-blue;
    border-radius: 50%;
    width: 161px;
    height: 161px;
    text-align: center;
    vertical-align: middle;
    margin: 1.5rem auto;

    .error-icon {
      padding-top: 44px;
      margin: auto;
    }
  }
}
.form-container {
  padding-top: 40px;
}
.pnr-msg {
  width: 80%;
  margin: auto;
}
</style>
