import Vue from 'vue'

export default Vue.component('ErrorBoundary', {
  props: {
    errorCaptured: {
      type: Function,
      required: true
    },
    propagateError: {
      type: Boolean,
      default: false
    }
  },
  errorCaptured(err, vm, info) {
    this.errorCaptured(err, vm, info)
    return this.propagateError
  },
  render() {
    return this.$slots.default
  }
})
