<template>
  <!-- eslint-disable max-len -->

  <div>
    <Banner
      :image-src="require('@/assets/icon-school-alt.svg')"
      :heading="bannerHeading"
    />
    <v-container fluid class="centeredContent">
      <div v-if="placementNotRequired" class="pb-10">
        <p class="pb-4">
          To advise your primary school that your child will not require
          placement in a NSW Government high school next year please sign in.
          Your primary school needs to know so they may support your child’s
          transition to high school and the department can fulfil its duty of
          care to all students.
        </p>
        <p>
          We ask you to authenticate so we can manage your information securely.
        </p>
      </div>
      <OesExternalLogin
        class="service-nsw-login"
        :image-src="require('@/assets/Service_NSW.svg')"
        :heading="serviceNswHeading"
        :is-pnr-flow="placementNotRequired"
        @beginAuth="beginOauth"
        @showPrivacy="handleShowPrivacyAgreement"
      >
        <template #text>
          <p v-if="placementNotRequired">
            You will be logged into your Service NSW account. When you are done,
            please remember to go to your service NSW account and log out if you
            are on a public computer.
          </p>
          <p v-else-if="isResuming">
            If you used your <strong>Service NSW account</strong>
            to begin your high school expression of interest, login to resume
            your progress
          </p>
          <p v-else>
            Logging in with your <strong>Service NSW account</strong>
            will allow you to start your high school expression of interest,
            save it and come back later
          </p>
        </template>
        <template v-if="!placementNotRequired" #additionalContent>
          <p class="grey-box">
            <strong>NOTE:</strong> You will be logged into your Service NSW
            account. When you are done, please remember to go to your service
            NSW account and log out if you are on a public computer.
          </p>
        </template>
      </OesExternalLogin>
      <div class="text-center login-divider"><strong>OR</strong></div>
      <ErrorBoundary :error-captured="handleAPIError">
        <OesEmailLogin
          ref="email"
          class="email-login"
          :email.sync="email"
          :heading="emailHeading"
          :is-pnr-flow="placementNotRequired"
          :include-recaptcha="!byPassRecaptcha"
          :recaptcha-site-key="recaptchaSiteKey"
          :email-error.sync="errors.email"
          :recaptcha-error.sync="errors.recaptcha"
          :image-src="require('@/assets/icon-email.svg')"
          image-alt="email"
          :loading.sync="loading"
          @authInitiated="handleAuthInitiated"
          @showPrivacy="handleShowPrivacyAgreement"
        >
          <template #text>
            <div v-if="!placementNotRequired">
              <p v-if="isResuming">
                Enter the email address you used to begin your expression of
                interest to resume your progress.
              </p>
              <p v-else>
                Entering your email address will allow you to start your high
                school expression of interest, save it and come back later
              </p>
            </div>
          </template>
        </OesEmailLogin>
      </ErrorBoundary>
      <PrivacyAgreementModal v-model="showPrivacyAgreement" max-width="764px" />
    </v-container>
  </div>
</template>

<script>
import Banner from '@/components/Banner'
import OesExternalLogin from '@/components/OesExternalLogin'
import PrivacyAgreementModal from '@/components/PrivacyAgreementModal'
import OesEmailLogin from '@/components/OesEmailLogin'
import ErrorBoundary from '@/components/error/ErrorBoundary'

import { mapGetters } from 'vuex'

import { NODE_ENVIRONMENTS, API_ERROR_SUBTYPES } from '@/constants'

const ERROR_MESSAGES = {
  EMAIL: 'Please enter a valid email address.',
  RECAPTCHA: 'Recaptcha verification failed. Please try again.'
}

export default {
  name: 'LoginView',
  components: {
    Banner,
    OesEmailLogin,
    PrivacyAgreementModal,
    OesExternalLogin,
    ErrorBoundary
  },
  props: {
    userEmail: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showPrivacyAgreement: false,
      loading: false,
      email: this.userEmail,
      recaptchaSiteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY,
      errors: {},
      placementNotRequired: false
    }
  },
  computed: {
    ...mapGetters([
      'oesProperties',
      'school',
      'isResuming',
      'preApplicationData',
      'localSecondarySchools'
    ]),
    byPassRecaptcha() {
      return (
        this.oesProperties?.bypassRecaptcha &&
        process?.env?.VUE_APP_ENV_NAME !== NODE_ENVIRONMENTS.PROD
      )
    },
    emailHeading() {
      if (this.placementNotRequired) {
        return 'Enter your email address to receive a verification code'
      }
      if (this.isResuming) {
        return 'Resume using your email address'
      }
      return 'Enter your email address'
    },
    serviceNswHeading() {
      return this.isResuming
        ? 'Resume using your Service NSW account'
        : 'Use your Service NSW account to begin'
    },
    bannerHeading() {
      return this.isResuming
        ? 'Enter your details to resume your application'
        : "Let's start with your details"
    },
    schoolName() {
      return this.school?.schoolName
    }
  },
  created() {
    if (this.$route.name === 'noplacement') {
      this.placementNotRequired = true
    }
  },
  mounted() {
    if (this.placementNotRequired) {
      this.$store.commit('setIsResuming', false)
      this.$store.commit('setIsPnrAdvice', true)
    } else {
      this.$store.commit('setIsPnrAdvice', false)
    }
  },
  methods: {
    beginOauth() {
      // Google Analytics
      this.$gtm.trackEvent({
        event: 'interaction',
        category: 'Authentication',
        action: 'Attempt',
        label: 'ServiceNSW',
        application: 'y67t'
      })
      sessionStorage.setItem('school', JSON.stringify(this.school))
      sessionStorage.setItem(
        'preApplicationData',
        JSON.stringify(this.preApplicationData)
      )
      sessionStorage.setItem(
        'localSecondarySchools',
        JSON.stringify(this.localSecondarySchools)
      )
      sessionStorage.setItem('isResuming', JSON.stringify(this.isResuming))
      sessionStorage.setItem(
        'isPnrAdvice',
        JSON.stringify(this.placementNotRequired)
      )
      this.$OAuth.getAuth()
    },
    handleAPIError(error) {
      const { response } = error
      // Specifically handle 400s with proper error info inline, else go to error view
      if (
        response &&
        response.status === 400 &&
        response.data &&
        response.data.errors
      ) {
        this.handleInlineErrors(response.data.errors)
      } else {
        this.$store.dispatch('setAPIError', {
          error,
          fallbackCode: API_ERROR_SUBTYPES.initiateAuth
        })
        this.$router.push({ name: 'error' })
      }
    },
    handleInlineErrors(errors) {
      this.errors = errors.reduce(
        (errors, error) => ({ ...errors, ...this.getErrorMessage(error) }),
        {}
      )
    },
    handleAuthInitiated(cognitoSession) {
      this.$store.dispatch('authInitiated', {
        cognitoSession,
        email: this.email
      })
      this.$router.push({ name: 'otp' })
    },
    getErrorMessage(error) {
      const genericError = { email: ERROR_MESSAGES.EMAIL }
      if (error && error.source) {
        switch (error.source.pointer) {
          case '/recaptchaToken':
            return { recaptcha: ERROR_MESSAGES.RECAPTCHA }
          case '/userId':
          default:
            return genericError
        }
      } else {
        return genericError
      }
    },
    handleShowPrivacyAgreement() {
      this.showPrivacyAgreement = true
    }
  }
}
</script>

<style lang="scss" scoped>
.login-divider {
  font-size: 1.5rem;
  text-align: center;
  margin: 2rem 0;
}

.centeredContent {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

::v-deep p {
  margin-bottom: 0;
}
</style>
