import moment from 'moment'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isBetween from 'dayjs/plugin/isBetween'

dayjs.extend(isBetween)
dayjs.extend(isSameOrBefore)

const converToISODate = (date) =>
  moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD')

const todayDate = dayjs().format('YYYY-MM-DD')

const checkIsBlockEoiProceed = (y67application) => {
  if (!y67application) {
    return false
  }
  const { startDate, closeDate, blockStartDate, blockEndDate } = y67application

  const duringEoiOpen = dayjs(todayDate).isBetween(
    startDate,
    dayjs(closeDate),
    null,
    // Including the value
    '[]'
  )
  const blockDate = dayjs(todayDate).isBetween(
    blockStartDate,
    dayjs(blockEndDate),
    null,
    '[]'
  )
  return !duringEoiOpen && blockDate
}

const checkIsBlockEoiProceedClosed = (isBlockEoiProceed, blockStartDate) => {
  const blockStartDecDate = dayjs(blockStartDate).format('YYYY-12-31')
  // Split the block date from 31st Dec, https://nsw-education.atlassian.net/browse/Y67T-3005
  return isBlockEoiProceed && dayjs(todayDate).isSameOrBefore(blockStartDecDate)
}

export { converToISODate, checkIsBlockEoiProceed, checkIsBlockEoiProceedClosed }
