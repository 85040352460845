import Vue from 'vue'
import Vuex, { Store } from 'vuex'

import authModule from './modules/auth'
import errorModule from './modules/error'
import formModule from './modules/form'
import refDataModule from './modules/refData'
import schoolModule from './modules/school'
import preApplicationModule from './modules/preApplication'
import stepperModule from './modules/stepper'
import pnrFormModule from './modules/pnrForm'

Vue.use(Vuex)

export default new Store({
  modules: {
    auth: authModule,
    error: errorModule,
    form: formModule,
    refData: refDataModule,
    school: schoolModule,
    preApp: preApplicationModule,
    stepper: stepperModule,
    pnrForm: pnrFormModule
  }
})
