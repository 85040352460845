<template>
  <div class="info-card__wrapper">
    <div class="info-card">
      <div
        class="info-card__icon-background"
        :style="{ backgroundColor: imageBackgroundColour }"
      >
        <slot name="icon">
          <img
            v-if="imageSrc"
            class="info-card__icon"
            :src="imageSrc"
            :alt="imageAlt"
          />
        </slot>
      </div>
      <slot name="content">
        <slot name="heading">
          <h3 v-if="heading" class="info-card__heading">
            {{ heading }}
          </h3>
        </slot>
        <slot name="body">
          <p class="info-card__body-text">
            <slot />
          </p>
        </slot>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoCard',
  props: {
    heading: {
      type: String
    },
    imageSrc: {
      type: String
    },
    imageAlt: {
      type: String
    },
    imageBackgroundColour: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.info-card {
  background: $ads-white;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0rem 1rem 1.5rem 1rem;

  &__wrapper {
    padding-top: 18%;
  }

  &__icon-wrapper {
    position: relative;
  }

  &__icon {
    width: 50%;
    height: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  &__icon-background {
    position: relative;
    margin-top: -18%;
    margin-bottom: 2rem;
    border-radius: 50%;
    width: 62%;
    padding-top: 62%;
    background: $ads-light-blue;
    z-index: 1;
  }

  &__heading {
    line-height: 1.5rem;
    font-size: 1.25rem;
    font-weight: 700;
    text-align: center;
    color: $color-text-body;
    margin-bottom: 1rem;
  }

  &__body-text {
    line-height: 1.5rem;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    color: $ads-navy;
    margin: 0;
  }
}
</style>
