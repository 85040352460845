function getStatusFromValidity(isValid) {
  switch (isValid) {
    case false:
      return 'invalid'
    case true:
      return 'complete'
    default:
      return 'initial'
  }
}

function schoolsToSteps(schools) {
  return schools.map((school, index) => ({
    key: `considerations-${index + 1}`,
    route: `/form/considerations/${index + 1}`,
    name: `${school.schoolName}`,
    title: `School ${index + 1} considerations`,
    status: getStatusFromValidity(school.valid)
  }))
}

// NOTE: 'currentStep' state + getter is in the form module.
// data is held in the meta part of the application object
const stepperModule = {
  getters: {
    steps: (state, getters) => {
      const schools = getters.ooaHighSchools?.map((school) => ({
        ...school,
        valid: getters.validConsiderations[school.schoolCode]
      }))
      const additionalSteps = schools ? schoolsToSteps(schools) : null
      return [
        {
          key: 'student',
          route: '/form/student',
          name: 'Student details',
          status: getStatusFromValidity(getters.validStudent)
        },
        {
          key: 'parentcarer',
          route: '/form/parentcarer',
          name: 'Parent/carer details',
          status: getStatusFromValidity(getters.validParentCarers)
        },
        {
          key: 'highschool',
          route: '/form/highschool',
          name: 'High school options',
          status: getStatusFromValidity(getters.validHighSchool)
        },
        ...(additionalSteps || []),
        {
          key: 'review',
          route: '/form/review',
          name: 'Review & submit'
        }
      ].map((stepInfo, index) => ({ ...stepInfo, step: index + 1 }))
    }
  }
}

export default stepperModule
