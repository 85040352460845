<template>
  <v-radio-group
    :ref="inputRef"
    class="radio-group"
    v-bind="attrs"
    :label="label"
    :rules="internalRules"
    v-on="$listeners"
    @change="$emit('input', $event)"
  >
    <v-radio
      v-for="item in items"
      :key="item.value"
      class="radio-group__button"
      :aria-describedby="messageBoxId"
      :aria-invalid="!isValid"
      v-bind="item"
      @blur="$emit('radioblur', $event)"
    />
    <slot />
    <template #message="data">
      <div :id="messageBoxId" class="v-messages__message">
        {{ data.message }}
      </div>
    </template>
  </v-radio-group>
</template>

<script>
import RequiredMixin from '@/mixins/required'
import accessibilityMixin from '@/mixins/fieldAccessibility'

export default {
  name: 'RadioGroup',
  mixins: [RequiredMixin, accessibilityMixin('radioGroup')],
  inheritAttrs: false,
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    attrs() {
      const defaultProps = {
        'persistent-hint': true
      }
      return { ...defaultProps, ...this.$attrs }
    }
  }
}
</script>

<style lang="scss" scoped>
.radio-group {
  ::v-deep label.v-label {
    font-weight: normal;
    color: $ads-navy;
  }
  ::v-deep .v-input--selection-controls__input {
    .v-icon {
      color: $ads-light-20;
    }
    .v-input--selection-controls__ripple:before {
      opacity: 0;
    }
  }
  ::v-deep .v-radio.v-radio--is-focused .v-input--selection-controls__ripple {
    border: 2px solid $ads-navy;
  }
}
</style>
