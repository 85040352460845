<template>
  <div class="SchoolNameLookup">
    <div class="ApplicationField">
      <div class="inputGroup" :class="[{ hasAlert: errorMessage }]">
        <label class="label" for="schoolNameLookupTypeAhead">
          {{ label }}
        </label>
        <div class="fieldContent fieldText">
          <TypeAhead
            id="schoolNameLookupTypeAhead"
            ref="typeAhead"
            :required="required"
            :disabled="disabled"
            :min-length="3"
            :max-length="100"
            placeholder="Enter student's primary school"
            :options="typeAheadOptions"
            :value="typeAheadValue"
            @input="onInput"
            @search="onSearch"
            @select="onSelect"
            @blur="onBlur"
          />
        </div>

        <div v-if="errorMessage" class="alertRow">
          <span class="alertMessage" data-testid="error-msg">{{
            errorMessage
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LOOKUP_STATE } from '@/constants'
import TypeAhead from './TypeAhead.vue'

export default {
  name: 'SchoolNameLookup',
  components: {
    TypeAhead
  },
  props: {
    id: String,
    value: String,
    label: [String, Boolean],
    disabled: Boolean,
    required: [Boolean, Function],
    selectedSchoolRegion: String,
    school: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      lookupSchools: [],
      selectedSchool: this.school,
      typeAheadOptions: [],
      typeAheadAlternates: [],
      typeAheadValue: this.value || '',
      isDirty: false,
      lookupState: LOOKUP_STATE.INITIAL,
      errorMessage: null
    }
  },
  computed: {
    getPredictiveSchools() {
      return this.$store.getters.predictiveSchools
    },
    pilotSchools() {
      return this.$store.getters.pilotSchools
    }
  },
  methods: {
    setValue(school) {
      this.$emit('change', school)
    },
    onInput(val) {
      this.typeAheadValue = val
      this.lookupState = LOOKUP_STATE.FILTERING
      if (val.length < 3) {
        this.close()
      }
      if (!val) {
        this.selectedSchool = null
      }
    },
    onSearch(val) {
      const predictiveSchools = this.getPredictiveSchools
      if (Array.isArray(predictiveSchools) && predictiveSchools.length > 0) {
        const filteredSchools = predictiveSchools.filter((item) => {
          if (item && item.orgUnitName && val) {
            const orgUnitNameWithSpace = item.orgUnitName
              .toLowerCase()
              .replace(/\s/g, '')
            const valWithoutSpace = val.toLowerCase().replace(/\s/g, '')
            if (orgUnitNameWithSpace.startsWith(valWithoutSpace)) {
              return true
            }
          }
          return false
        })
        const filterYear7Catchment = [
          'Primary Schools',
          'Central/Community Schools',
          'Schools for Specific Purposes'
        ]
        const filterCatchmentSchools = filteredSchools.filter((school) => {
          return filterYear7Catchment.includes(school.catchmentLevel)
        })
        this.lookupSchools = filterCatchmentSchools
        if (filteredSchools && filteredSchools.length > 0) {
          this.typeAheadOptions = filterCatchmentSchools.map(
            (item) => item.orgUnitName
          )
          this.typeAheadAlternates = filterCatchmentSchools.map(
            (item) => item.suburb
          )
        }
      }
    },
    isPilotSchool(schoolCode) {
      const filteredPilot = this.pilotSchools.filter(
        (x) =>
          x.schoolCode === schoolCode &&
          (x.catchmentLevel === 'primary' || x.catchmentLevel === 'central') &&
          x.y67t === true
      )
      return filteredPilot.length > 0
    },
    onSelect(idx) {
      this.errorMessage = ''
      if (this.lookupSchools) {
        this.selectedSchool = this.lookupSchools[idx]
      }
      if (this.typeAheadOptions) {
        this.typeAheadValue = this.typeAheadOptions[idx]
      }
      this.lookupState = LOOKUP_STATE.SELECTED
      this.setValue(this.selectedSchool)
      this.close()
    },
    onBlur() {
      this.errorMessage = ''
      if (this.typeAheadValue === '') {
        this.setValue(null)
        this.errorMessage = 'Please make a selection'
      } else if (this.lookupState === LOOKUP_STATE.FILTERING) {
        this.setValue(null)
        this.errorMessage =
          'We cannot verify this school. Please clear the field and search for the school again.'
      } else {
        this.setValue(this.selectedSchool)
      }
      this.close()
    },
    close() {
      this.typeAheadAlternates = []
      this.typeAheadOptions = []
    }
  }
}
</script>

<style scoped lang="scss">
.SchoolNameLookup {
  .ApplicationField {
    .fieldText {
      width: 100% !important;
    }
  }

  .fieldContent {
    input {
      width: 100%;
    }
  }

  .alertRow {
    display: flex;
    padding-top: 0.5rem;

    svg {
      position: relative;
      top: -0.5rem;
      height: 3em;
      color: $ads-red;
    }

    .alertMessage {
      display: inline-block;
      padding-left: 0.25rem;
      color: $ads-primary-red;
      font-size: 1rem;
    }
  }
  .label {
    @include vuetify-label;
    margin-left: 0.6rem;
    padding: 0 0.2rem;
  }

  .hasAlert {
    label {
      color: $ads-primary-red;
    }
    ::v-deep .AppTypeAhead_input {
      border: 2px solid $ads-primary-red;
      background: url('~@/assets/icon-magnify-red.svg') no-repeat scroll 13px
        18px;
    }
  }
}
</style>
