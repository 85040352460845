/* eslint-disable no-param-reassign */

import authApi from 'api-client/auth'
import jwtDecode from 'jwt-decode'

const authModule = {
  state: {
    email: null,
    cognitoSession: null,
    idToken: null,
    refreshToken: null,
    authSource: null
  },
  getters: {
    email: (state) => state.email,
    cognitoSession: (state) => state.cognitoSession,
    idToken: (state) => state.idToken,
    refreshToken: (state) => state.refreshToken,
    isAuthenticated: (state) => !!(state.refreshToken && state.idToken),
    authSource: (state) => state.authSource
  },
  mutations: {
    setEmail(state, email) {
      state.email = email
    },
    setCognitoSession(state, cognitoSession) {
      state.cognitoSession = cognitoSession
    },
    setIdToken(state, idToken) {
      state.idToken = idToken
    },
    setRefreshToken(state, refreshToken) {
      state.refreshToken = refreshToken
    },
    setAuthSource(state, source) {
      state.authSource = source
    }
  },
  actions: {
    refreshToken({ commit, state }) {
      return authApi
        .refreshToken(state.refreshToken, state.email)
        .then((response) => {
          commit('setIdToken', response.body.idToken)
          return response
        })
    },
    inactivateSession({ commit }) {
      commit('setCognitoSession', null)
      commit('setIdToken', null)
      commit('setRefreshToken', null)
    },
    logout({ commit, dispatch }) {
      commit('setEmail', null)
      dispatch('inactivateSession')
    },
    authInitiated({ commit }, { cognitoSession, email }) {
      commit('setCognitoSession', cognitoSession)
      commit('setEmail', email)
    },
    setTokens({ commit }, { idToken, refreshToken }) {
      commit('setIdToken', idToken)
      commit('setRefreshToken', refreshToken)
      commit('setEmail', jwtDecode(idToken).email)
    },
    reHydrateDataFromOauth({ commit }) {
      // Get from storage
      const preApplicationData = JSON.parse(
        sessionStorage.getItem('preApplicationData')
      )
      const localSecondarySchools = JSON.parse(
        sessionStorage.getItem('localSecondarySchools')
      )
      const school = JSON.parse(sessionStorage.getItem('school'))
      const isResuming = JSON.parse(sessionStorage.getItem('isResuming'))
      const isPnrAdvice = JSON.parse(sessionStorage.getItem('isPnrAdvice'))

      // Set in store
      commit('setPreApplicationData', preApplicationData)
      commit('setSchool', school)
      commit('setLocalSecondarySchools', localSecondarySchools)
      commit('setIsResuming', isResuming)
      commit('setIsPnrAdvice', isPnrAdvice)

      // Remove from storage
      sessionStorage.removeItem('preApplicationData')
      sessionStorage.removeItem('localSecondarySchools')
      sessionStorage.removeItem('school')
      sessionStorage.removeItem('isResuming')
      sessionStorage.removeItem('isPnrAdvice')
    }
  }
}

export default authModule
